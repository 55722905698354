import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";

import {Cell, Column, Table} from "fixed-data-table-2";
import Dimensions from "react-dimensions";

import Tooltip from "js/common/views/tooltips";
import {TextButton} from "js/common/views/inputs/buttons";
import * as popups from "js/common/popups";
import * as strings from "js/common/utils/strings";
import saveAsCsv from "js/common/save-as-csv";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import ReactTooltip from "react-tooltip";
import {getIsDeepLinkable} from "js/common/utils/novo-comms";
import Icon from "js/admin/common/icon";
import * as ajax from "js/common/ajax";
import Immutable from "immutable";
import FileSaver from "browser-filesaver";
import ErrorMsg from "js/common/views/error";

const _ = window._;

// TODO immutable?
// TODO column index should become unique key, allows for columns to be decoupled from row layout

const identity = cell => cell;
const ROW_HEIGHT = 30;
const HEADER_ROW_HEIGHT = 35;

const AutoDimensionTable = Dimensions()(({
  children,
  containerWidth,
  themeId,
  ...props
}) => (
    <div>
      <Table
          {...props}
          width={containerWidth}
          headerHeight={HEADER_ROW_HEIGHT}
          rowHeight={ROW_HEIGHT}
          touchScrollEnabled={true}>
        {children}
      </Table>
      <ReactTooltip type={themeId === "light" ? "dark" : "light"} className="cube19-limit-width" />
    </div>
));

const SimpleDataTable = createReactClass({

  displayName: "SimpleDataTable",

  propTypes: {
    rows: ReactPropTypes.array.isRequired,
    columns: ReactPropTypes.array.isRequired,
    rowLimitReached: ReactPropTypes.bool,

    maxTableHeight: ReactPropTypes.any,
    initialSortBy: ReactPropTypes.number,
    initialSortDirection: ReactPropTypes.string,
    onCellClick: ReactPropTypes.func,
    onSearch: ReactPropTypes.func,

    downloadable: ReactPropTypes.bool,
    showGreyButtonIfNotDownloadable: ReactPropTypes.bool,
    filenameForDownload: ReactPropTypes.string,
    onDownloadClick: ReactPropTypes.func,
    parentHandlingDownload: ReactPropTypes.bool,

    searchable: ReactPropTypes.bool,
    id: ReactPropTypes.string,

    tableType: ReactPropTypes.string,
    uploadType: ReactPropTypes.string
  },

  getDefaultProps() {
    return {
      maxTableHeight: 300,
      initialSortBy: 0,
      initialSortDirection: "ASC",
      onCellClick: () => {},
      onSearch: () => {},
      downloadable: false,
      showGreyButtonIfNotDownloadable: false,
      filenameForDownload: "Data.csv",
      onDownloadClick: () => {},
      parentHandlingDownload: false,

      searchable: true
    };
  },

  getInitialState() {
    const columns = this.props.columns.map((column, index) => parseColumn(column, index, this.props.rows));
    const rows = parseRows(this.props.rows, columns);

    const sortBy = this.props.initialSortBy;
    const sortDirection = this.props.initialSortDirection;
    const sortedRows = columns.length === 0 ? rows : applySort(rows, sortBy, sortDirection);

    return {
      sortBy,
      sortDirection,
      filterText: "",
      originalRows: rows,
      rows: sortedRows,
      columns,
      columnWidths: calculateColumnWidths(columns.map(c => c.label), rows, this.props.tableType)
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.columns === this.props.columns && nextProps.rows === this.props.rows) {
      return;
    }
    if (nextProps.columns.length === 0) {
      return;
    }

    if (nextProps.rows.length !== `this.props.rows`.length) {
      // we must force a resize event to ensure AutoDimensionTable adjusts its height based on new content
      window.dispatchEvent(new Event("resize"));
    }

    const columns = nextProps.columns.map((column, index) => parseColumn(column, index, nextProps.rows));
    const rows = parseRows(nextProps.rows, columns);

    const sortBy = this.state.sortBy;
    const sortDirection = this.state.sortDirection;
    const sortedRows = applySort(rows, sortBy, sortDirection);
    const sortedFilteredRows = applyFilter(this.state.filterText, sortedRows);

    this.setState({
      originalRows: rows,
      rows: sortedFilteredRows,
      columns,
      columnWidths: calculateColumnWidths(columns.map(c => c.label), sortedFilteredRows, this.props.tableType)
    });
  },

  render() {
    const rowsCount = this.state.rows.length;
    const tableHeight = Math.min(ROW_HEIGHT * (rowsCount + 3), this.props.maxTableHeight);
    const {theme, rowLimitReached} = this.props;
    return (
        <div id={this.props.id} style={{paddingTop: "0.5rem"}}>
          <div style={{width: "100%", textAlign: "right"}}>
            {rowLimitReached && <ErrorMsg
                type="warn"
                text={"A limit of 500k rows are shown below. If you need to see data that is not loaded, please adjust your filters."} />}
            <div style={{float: "left", paddingLeft: "1rem"}}>
              {this.renderDownloadButton()}
            </div>
            {this.props.searchable && <Filter
                theme={theme}
                value={this.state.filterText}
                onChange={this.handleFilterChange} />}
          </div>
          <div className={`table-${theme.themeId}`}>
            <AutoDimensionTable rowsCount={rowsCount} height={tableHeight} themeId={theme.themeId}>
              {this.state.columns.map(this.renderColumn)}
            </AutoDimensionTable>
          </div>
        </div>);
  },

  renderDownloadButton() {
    const buttonLabel = "Download";
    const tooltipLabel = "Ask an admin user for the 'Export To File' permission to download this data";
    if (this.props.downloadable) {
      return <TextButton type="default" label={buttonLabel} onClick={this.handleDownloadClick} />;
    } else if (this.props.showGreyButtonIfNotDownloadable) {
      return (
          <Tooltip text={tooltipLabel} position="right">
            <TextButton
                disabled={true}
                type="default"
                label={buttonLabel}
                onClick={() => popups.alert(tooltipLabel, {title: "Permission required to download"})}
            />
          </Tooltip>
      );
    } else {
      return null;
    }
  },

  renderColumn(column, index) {
    const {theme, tableType, uploadType} = this.props;
    const header = (
        <SortHeader
            palette={theme.palette}
            column={column}
            direction={this.getSortDirectionForColumn(index)}
            onClick={() => this.handleSortChange(index)} />);
    const cell = this.getCellForColumn(theme, column, index, tableType, uploadType);
    return (
        <Column
            key={index}
            header={header}
            cell={cell}
            flexGrow={column.flexGrow}
            width={column.width || this.state.columnWidths[index] || 180} />);
  },

  getCellForColumn(theme, column, index, tableType, uploadType) {
    const CellClass = types[column.type].cell || DefaultCell;
    return (
        <CellClass
            tableType={tableType}
            uploadType={uploadType}
            theme={theme}
            columnIndex={index}
            rows={this.state.rows}
            onClick={this.props.onCellClick} />);
  },

  getSortDirectionForColumn(index) {
    return index === this.state.sortBy ? this.state.sortDirection : "NONE";
  },

  handleDownloadClick() {
    const headerRow = this.state.columns.map(c => c.label);
    const dataRows = this.state.rows.map(row => row.map(c => c.displayValue));

    if (this.props.parentHandlingDownload) {
      this.props.onDownloadClick(headerRow, dataRows);
    } else {
      const rows = [headerRow].concat(dataRows);
      saveAsCsv(rows, this.props.filenameForDownload);
      this.props.onDownloadClick();
    }
  },

  handleSortChange(index) {
    const sortBy = index;
    const sortDirection = nextSortDirection(this.getSortDirectionForColumn(index));
    const sortedRows = applySort(this.state.rows, sortBy, sortDirection);
    this.setState({
      sortBy,
      sortDirection,
      rows: sortedRows
    });
  },

  handleFilterChange(e) {
    this.props.onSearch();
    const filterText = e.target.value;
    const continuesFromPreviousFilter = filterText.length > this.state.filterText.length;
    const rowsToFilter = continuesFromPreviousFilter ? this.state.rows : this.state.originalRows;
    const filteredRows = applyFilter(filterText, rowsToFilter);

    const sortBy = this.state.sortBy;
    const sortDirection = this.state.sortDirection;
    const sortedFilteredRows = continuesFromPreviousFilter ? filteredRows : applySort(
        filteredRows,
        sortBy,
        sortDirection);
    this.setState({
      filterText,
      rows: sortedFilteredRows
    });
  }
});


export const textFilterStyle = theme => ({
  borderRadius: "0.2rem",
  border: theme.themeId === "light" ? "1px solid #dbdbdb" : 0,
  borderColor: "none",
  fontSize: "0.85rem",
  color: theme.palette.text.main,
  backgroundColor: theme.palette.background.card,
  marginBottom: 10,
  marginRight: 10,
  padding: "7px 12px",
  float: "right",
  width: "50%",
  fontFamily: theme.typography.fontFamily
});

export const inputFilterStyle = theme => ({
  fontSize: "14px",
  border: "none",
  width: "100%",
  paddingLeft: "10px",
  backgroundColor: theme.palette.background.card,
  color: theme.palette.text.main,
  fontFamily: theme.typography.fontFamily
});

const Filter = ({
  theme,
  value,
  onChange
}) => (
    <div style={textFilterStyle(theme)}>
        <span
            style={{display: "flex", alignItems: "center"}}
            data-test-id="simple-data-table-search"><i className="fa fa-search" />
        <input
            style={inputFilterStyle(theme)}
            value={value}
            placeholder="Search for..."
            onChange={onChange} /></span>
    </div>);

const parseRows = (rows, columns) => {
  return rows.map(row => {
    const newRow = row.map((originalValue, index) => {
      const column = columns[index];
      const commonMappedValue = column.commonMapper(originalValue);
      return {
        originalValue,
        sortValue: column.sortMapper(commonMappedValue, originalValue),
        displayValue: column.displayMapper(commonMappedValue, originalValue),
        columnName: column.label.replace(" ", "").toLowerCase()
      };
    });
    /*
     * awful hack to preserve id on table rows, mainly for click events
     * potentially fixed by having rows as objects instead of arrays
     */
    newRow.id = row.id;
    return newRow;
  });
};

const parseColumn = (column, index, rows) => {
  if (typeof column === "string") {
    column = {label: column};
  }

  if (!column.type && rows.length > 0) {
    /*
     * very hacky type detection, requiring invalid usage of display and common mappers
     * this will break if core types define a common mapper
     */
    const firstRow = rows[0] || [];
    const displayMapper = (column.displayMapper || identity);
    const commonMapper = (column.commonMapper || identity);
    const cell = displayMapper(commonMapper(firstRow[index]));
    column.type = detectType(cell);
  } else {
    column.type = "STRING";
  }
  column.sortMapper = column.sortMapper || types[column.type].sortMapper || identity;
  column.displayMapper = column.displayMapper || types[column.type].displayMapper || identity;
  column.commonMapper = column.commonMapper || types[column.type].commonMapper || identity;
  column.flexGrow = column.flexGrow === undefined ? 1 : column.flexGrow;
  return column;
};

const detectType = cell => {
  if (typeof cell === "number") {
    return "NUMBER";
  } else {
    return "STRING";
  }
};

const CELL_PADDING = 8 * 2;
const MAX_WIDTH = 350;
const PIXELS_PER_CHAR = 8;
const ADJUSTMENT_FOR_SORT_ARROW = 2;
const calculateColumnWidths = (columnLabels, rows, tableType) => {
  const maxCellLengths = [];
  columnLabels.forEach((label, index) => {
    const length = (label + "").length + ADJUSTMENT_FOR_SORT_ARROW;
    if (!maxCellLengths[index] || maxCellLengths[index] < length) {
      maxCellLengths[index] = length;
    }
  });
  rows.forEach(row => {
    row.forEach((cell, index) => {
      let length = (cell.displayValue + "").length;
      length = cell.columnName === "status" && tableType === "UPLOAD_SUMMARY" ? length + 10 : length;
      if (!maxCellLengths[index] || maxCellLengths[index] < length) {
        maxCellLengths[index] = length;
      }
    });
  });

  return maxCellLengths.map(length => CELL_PADDING + Math.min(MAX_WIDTH, length * PIXELS_PER_CHAR));
};

const headerStyle = palette => ({
  backgroundColor: palette.background.card,
  height: "100%"
});

const SortHeader = ({palette, column, direction, onClick}) => (
    <Cell style={headerStyle(palette)} onClick={onClick}>
      <span>{column.label}</span> <i style={{paddingLeft: "5px"}} className={sortArrowByDirection[direction]} />
    </Cell>);

const nextSortDirection = direction => direction === "ASC" ? "DESC" : "ASC";
const sortArrowByDirection = {
  ASC: "bhi-arrow-up",
  DESC: "bhi-arrow-down",
  NONE: ""
};

const applyFilter = (text, rows) => {
  if (!text) {
    return rows;
  } else {
    const words = text.toLowerCase().split(" ");
    return rows.filter(row => {
      const rowStr = row.map(cell => cell.displayValue).join(" ").toLowerCase();
      return _(words).all(word => rowStr.indexOf(word) !== -1);
    });
  }
};

const applySort = (rows, sortByColumnIndex, sortDirection) => {
  if (sortByColumnIndex === null) {
    return rows;
  } else {
    const sortFn = sortDirection === "ASC" ? defaultSort : reverseSort;
    return rows
        .slice()
        .sort((a, b) => sortFn(a[sortByColumnIndex].sortValue, b[sortByColumnIndex].sortValue));
  }
};

const defaultSort = (a, b) => a > b ? 1 : (a < b ? -1 : 0);
const reverseSort = (a, b) => a > b ? -1 : (a < b ? 1 : 0);

const DefaultCell = ({rows, rowIndex, columnIndex, onClick, theme, tableType, uploadType, ...props}) => {
  const row = rows[rowIndex];
  const cell = row[columnIndex];
  const deepLinkingData = cell.originalValue.deepLinkingData;
  const isDeepLinkable = getIsDeepLinkable(deepLinkingData);
  const onThisCellClick = _(onClick).partial(cell.displayValue, row, columnIndex, rowIndex, isDeepLinkable);
  const cellStyle = {
    whiteSpace: "nowrap",
    width: props.width - CELL_PADDING
  };

  const deepLinkableStyle = {
    color: theme.palette.primary.main,
    cursor: "pointer"
  };

  const isEvenRow = rowIndex % 2 === 0;

  if (isDeepLinkable) {
    const dataTipMessage = `Clicking this link will navigate you to the Bullhorn ATS ${deepLinkingData.crmEntityName} record in a new tab.`;
    const dataTipTitle = cell.displayValue === deepLinkingData.crmId.toString()
        ? `<span style="font-weight: bold">ID: #${cell.displayValue}</span>`
        : `<span style="font-weight: bold; max-width: 60%;  white-space: nowrap; overflow: hidden">${cell.displayValue}</span><span style="white-space: nowrap; overflow: hidden; max-width: 35%">ID: #${deepLinkingData.crmId}</span>`;
    const dataTip = `<div><div style='display: flex; justify-content: space-between; margin-bottom: 5px; margin-top: 2px;'>${dataTipTitle}</div>${dataTipMessage}</div>`;

    return (
        <Cell
            onMouseEnter={() => {
              ReactTooltip.show();
            }}
            onMouseLeave={() => {
              ReactTooltip.hide();
            }}
            className={isEvenRow ? "cell-even" : "cell-odd"}
            {...props}>
          <div
              style={cellStyle}>
                <span
                    className={"TESTCAFE-deep-linked-cell-" + cell.displayValue}
                    data-tip={dataTip}
                    data-html={true}
                    style={deepLinkableStyle}
                    onClick={onThisCellClick}>
                    {cell.displayValue}
                </span>
          </div>
        </Cell>);
  } else {
    return (
        <Cell
            className={isEvenRow ? "cell-even" : "cell-odd"}
            {...props}
            onClick={onThisCellClick}>
          <div style={cellStyle} data-test-id="simple-data-table-default-non-deep-linkable-cell">
            {tableType === "UPLOAD_SUMMARY" ? renderDisplayValue(cell, row, theme, uploadType) : cell.displayValue}
          </div>
        </Cell>);
  }
};

const renderDisplayValue = (cell, row, theme, uploadType) => {

  const jobId = row.find(r => r.columnName === "id") && row.find(r => r.columnName === "id").originalValue;
  const jobStatus = row.find(r => r.columnName === "status") && row.find(r => r.columnName === "status").originalValue;

  const statusColourMap = {
    "COMPLETED": theme.palette.success.main,
    "PENDING": theme.palette.warning.main,
    "PROCESSING": theme.palette.warning.main,
    "FAILED": theme.palette.error.main
  };

  const fileUrlMap = {
    "users": `user-upload/jobs/${jobId}/csv`,
    "targets": `targets/bulk/jobs/${jobId}/csv`,
    "groups": `group-upload/jobs/${jobId}/csv`
  };

  if (cell.columnName === "status") {
    return (
        <div>
          {cell.displayValue !== "FAILED" && "Moved to "}
          <span style={{color: statusColourMap[cell.displayValue]}}>{cell.displayValue}</span>
          {cell.displayValue === "FAILED" && " (Click to view)"}
        </div>
    );
  } else if (cell.columnName === "filename") {
    return <a
        onClick={() => onCsvDownloadClick(fileUrlMap[uploadType])}
        style={{color: theme.palette.primary.main}}>{cell.displayValue}</a>;
  } else if (cell.columnName === "id") {
    return jobStatus === "FAILED"
        ? <span><Icon
            icon="caution"
            style={{color: theme.palette.error.main}} /> {cell.displayValue}</span>
        : cell.displayValue;
  } else {
    return cell.displayValue;
  }
};

const onCsvDownloadClick = filename => {
  ajax
      .get({url: filename, returnFileName: true})
      .then(response => {
        downloadCsv(Immutable.fromJS(response.body), response.fileName);
      }).catch(e => popups.error(e.responseJSON.message));
};

const downloadCsv = (csv, header) => {
  const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
  FileSaver.saveAs(blob, header);
};

const numberRegex = /^\d+$/;

// TODO could identify money and numbers beforehand to improve performance, won't work well for mixed data in columns
const types = {
  STRING: {
    sortMapper: x => {
      if (typeof (x) === "string") {
        if (x && strings.isMonetaryValue(x + "")) {
          return parseFloat(x.replace(/[^-?0-9\.]+/g, ""));
        } else if (x && x.match(numberRegex)) {
          return parseFloat(x);
        } else {
          return (x + "").toLowerCase();
        }
      } else if (typeof (x) === "number") {
        return x;
      } else {
        return "";
      }
    }
  },
  NUMBER: {}
};

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <SimpleDataTable theme={theme} {...props} />;
};

export default Wrapper;
