import React from "react";
import InputRange from "react-input-range";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export default React.memo(({explanation, disabled, range, limit, shortcuts, onChange}) => {

    const { theme } = React.useContext(CustomThemeContext);

    const shortcutStyle = {
        fontSize: "0.75rem",
        padding: "0.15rem 0.3rem",
        marginRight: "0.4rem",
        borderRadius: "0.3rem",
        backgroundColor: theme.palette.action.disabledBackground,
        cursor: "pointer",
        ":hover": {
            backgroundColor: "#777"
        }
    };

    const wrappedOnChange = (min, max) => {
        if (disabled) {
            return;
        }

        if (min < 0) {
            min = 0;
        }
        if (max > limit) {
            max = limit;
        }
        onChange(min, max);
    };
    return (
        <div style={{position: "relative", marginTop: "1.2rem"}} className={`day-range-${theme.themeId}`} data-test-id="day-range-slider">
            <div style={{position: "absolute", top: "-1.7rem", left: "2rem"}}>
                {shortcuts.map(s => {
                    const dayRange = s.get("dayRange");
                    const selected = range.get("min") === dayRange.get("min")
                        && range.get("max") === dayRange.get("max");
                    const style = (selected && !disabled) ?
                        {...shortcutStyle, backgroundColor: theme.palette.primary.main, color: theme.palette.text.inverted} : shortcutStyle;
                    return (
                        <span
                            key={s.get("label")}
                            onClick={() => wrappedOnChange(dayRange.get("min"), dayRange.get("max"))}
                            style={style}>
                            {s.get("label")}
                        </span>
                    );
                })}
            </div>
            <div style={{position: "absolute", top: "1.4rem", left: 0, fontSize: "12px"}}>
                {explanation}
            </div>
            <InputRange
                formatLabel={value => {
                    if (value === limit) {
                        return (limit - 1) + "+";
                    } else {
                        return value;
                    }
                }}
                disabled={disabled}
                allowSameValue={false}
                draggableTrack={true}
                minValue={0}
                maxValue={limit}
                value={range.toJS()}
                onChange={({min, max}) => wrappedOnChange(min, max)} />
        </div>
    );
});
