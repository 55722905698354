import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import GroupUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";

import KpiPicker from "js/common/views/kpis/kpi-picker";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import TagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";
import ClientFilter from "js/common/views/inputs/organisation-picker/react-organisation-picker";
import NonClientFilterableKpisInfoDialog
  from "js/common/views/inputs/organisation-picker/non-client-filterable-kpis-info-dialog";
import ThinSectionHeader from "js/common/views/thin-section-header";
import PlotChartButton from "js/charting/trend/plot-chart-button";
import NoKpisSelectedWarning from "js/charting/trend/no-kpis-selected-warning";
import ChartNameInput from "js/charting/trend/chart-name-input";
import {Column} from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import currentClient from "js/common/repo/backbone/current-client";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as auditor from "js/common/auditer";
import * as Groups from "js/common/groups";
import Checkbox from 'js/common/views/inputs/checkbox';
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const StandardCharting = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    initialConfig: ImmutablePropTypes.map.isRequired,
    onAdvancedChartClick: ReactPropTypes.func.isRequired,
    onPlotChartClick: ReactPropTypes.func.isRequired
  },

  getInitialState() {
    return {
      config: this.props.initialConfig,
      isNonClientFilterableKpisInfoDialogOpen: false,
      isNoKpisSelectedWarningOpen: false
    };
  },

  render() {
    const {config, isNoKpisSelectedWarningOpen} = this.state;
    const hasKpisSelected = !config.get("kpiIds").isEmpty();
    const onPlotChartClickHandler = hasKpisSelected ? this.onPlotChartClick : this.openNoKpisSelectedWarning;
    const isClientFilterActive = config.get("clientIds") && !config.get("clientIds").isEmpty();
    const timeframes = getRequiredTimeframes();
    const timeframe = config.get("timeframe");
    const visibleTimeframes = timeframes
        .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === timeframe.get("type"));
    const selectedKpiIds = config.get("kpiIds");
    const kpis = getTrendableKpis();
    const visibleKpis = kpis.filter(kpi => kpi.get("visible") || selectedKpiIds.includes(kpi.get("id")));
    return (
        <div>
          <ChartNameInput
              name={config.get("name")}
              onChange={name => this.setState({config: config.set("name", name)})} />
          <div style={{paddingTop: "1rem", ...fullWidth}} className="clearfix">
            <Column small={12} medium={6}>
              <Checkbox
                  label="Show Data Cumulatively"
                  checked={config.get("displayDataCumulatively")}
                  onCheck={(e, isChecked) => {
                    this.setState({config: config.set("displayDataCumulatively", isChecked)});
                  }}
                  iconStyle={{paddingTop: 0, paddingBottom: 0, marginRight: 8}}
                  style={{width: "100%"}} />
              <Checkbox
                  label="Exclude Weekends"
                  checked={config.get("excludeWeekends")}
                  iconStyle={{paddingTop: 0, paddingBottom: 0, marginRight: 8}}
                  onCheck={(e, isChecked) => {
                    this.setState({config: config.set("excludeWeekends", isChecked)});
                  }}
                  style={{width: "100%"}} />
              {config.get("qualifierType") === "GROUP" &&
              <Checkbox
                  label={"Show Average by User"}
                  iconStyle={{paddingTop: 0, paddingBottom: 0, marginRight: 8}}
                  checked={config.get("averageByUser")}
                  onCheck={(e, isChecked) => {
                    this.setState({config: config.set("averageByUser", isChecked)});
                  }}
                  style={{width: "100%"}} />}
            </Column>

            <Column small={12} medium={6} className="text-right">
              {currentClient.hasPermission("ADVANCED_TREND_CHARTING") &&
              <TextButton
                  type="dark"
                  label="Advanced Trend Chart"
                  style={{
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem"
                  }}
                  onClick={() => this.props.onAdvancedChartClick(config)} />}
              <PlotChartButton
                  style={{
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem"
                  }}
                  isDisabled={!hasKpisSelected}
                  onClick={onPlotChartClickHandler} />
            </Column>
          </div>

          {isNoKpisSelectedWarningOpen &&
          <NoKpisSelectedWarning onRequestClose={this.closeNoKpisSelectedWarning} />}

          <section className="page-section clearfix" style={{marginTop: "0.5rem"}}>
            <ThinSectionHeader icon="line-chart" label="Add Metrics" />
            <div style={fullWidth}
                 data-test-id="charting_kpi_picker">
              <Column small={12} medium={6} style={{clear: "both", ...spacer}}>
                <KpiPicker
                    id="TESTCAFE_charting_kpi_picker"
                    disableNonClientFilterableKpis={isClientFilterActive}
                    kpis={visibleKpis}
                    selectedKpiIds={selectedKpiIds.size > 0 ? selectedKpiIds : null}
                    onChange={this.handleChangeKpis}
                    multiSelect={true} />
              </Column>
            </div>
          </section>

          <section className="page-section clearfix">
            <ThinSectionHeader icon="group" label="Choose a User or Group" />
            <div style={fullWidth}>
              <Column small={12} medium={6} style={spacer}>
                <GroupUserPicker
                    showLoggedInUserOptions={true}
                    hierarchy={Groups.getHierarchyWithUsers(false)}
                    qualifierId={config.get("qualifierId")}
                    qualifierType={config.get("qualifierType")}
                    onGroupClick={this.handleChangeGroup}
                    onUserClick={this.handleChangeUser} />
              </Column>
            </div>
          </section>

          <section className="page-section clearfix">
            <ThinSectionHeader icon="clock-o" label="Choose a Timeframe" />
            <div style={fullWidth}>
              <Column small={12} medium={6} style={spacer}>
                <TimeframePicker
                    timeframes={visibleTimeframes}
                    timeframe={getTimeframe(timeframe)}
                    onChange={timeframe => {
                      this.setState({
                        config: config.set("timeframe", Immutable.fromJS(timeframe.getRawJson()))
                      });
                    }} />
              </Column>
            </div>
          </section>

          {currentClient.canAccessApp("TAGS_ADMIN") && this.renderTagFilters()}
          {currentClient.hasPermission("ORGANISATION_FILTER") && this.renderClientFilter()}
        </div>);
  },

  renderTagFilters() {
    const {config} = this.state;
    const showIcon = false;
    const allowMultiple = true;
    const columnStyle = {marginTop: "1rem"};
    return (
        <section className="page-section clearfix" style={{marginBottom: "1em"}}>
          <ThinSectionHeader icon="tags" label="Filter data by tags" />
          <div style={fullWidth}>
            <Column small={12} medium={4} style={columnStyle}>
              <TagFilter
                  label="Match Any Tags"
                  showIcon={showIcon}
                  onChange={matchAnyTagIds => {
                    this.setState({config: config.set("matchAnyTagIds", matchAnyTagIds)});
                  }}
                  tagIds={config.get("matchAnyTagIds")}
                  multiple={allowMultiple} />
            </Column>
            <Column small={12} medium={4} style={columnStyle}>
              <TagFilter
                  label="Match All Tags"
                  showIcon={showIcon}
                  onChange={matchAllTagIds => {
                    this.setState({config: config.set("matchAllTagIds", matchAllTagIds)});
                  }}
                  tagIds={config.get("matchAllTagIds")}
                  multiple={allowMultiple} />
            </Column>
            <Column small={12} medium={4} style={columnStyle}>
              <TagFilter
                  showIcon={showIcon}
                  label="Exclude Tags"
                  onChange={excludedTagIds => {
                    this.setState({config: config.set("excludedTagIds", excludedTagIds)});
                  }}
                  tagIds={config.get("excludedTagIds")}
                  multiple={allowMultiple} />
            </Column>
          </div>
        </section>
    );
  },

  renderClientFilter() {
    const {config, isNonClientFilterableKpisInfoDialogOpen} = this.state;
    const {theme} = this.props;
    const nonClientFilterableKpis = this.getNonClientFilterableKpisInConfig();
    const hasNonClientFiltereableKpisSelected = nonClientFilterableKpis.length > 0;
    const warning = hasNonClientFiltereableKpisSelected && this.renderNonClientFilterableKpisWarningInfo(theme);
    return (
        <section className={`page-section clearfix table-${theme.themeId}`}>
          <ThinSectionHeader icon="building" label="Filter data by client" />
          <div style={fullWidth}>
            <Column small={12} medium={6} style={spacer}>
              <label>Clients {warning}</label>
              <ClientFilter
                  onChange={clientIds => this.setState({config: config.set("clientIds", clientIds)})}
                  clientIds={config.get("clientIds")}
                  isDisabled={nonClientFilterableKpis.length > 0} />
              <NonClientFilterableKpisInfoDialog
                  isOpen={isNonClientFilterableKpisInfoDialogOpen}
                  onClose={this.closeNonClientFilterableKpisInfoDialog}
                  nonClientFilterableKpis={nonClientFilterableKpis} />
            </Column>
          </div>
        </section>);
  },

  getNonClientFilterableKpisInConfig() {
    let nonClientFilterableKpis = [];
    this.state.config.get("kpiIds").forEach(kpiId => {
      const kpi = kpiRepo.get(kpiId);
      if (kpi && !kpi.get("filterableByClient")) {
        nonClientFilterableKpis.push(kpi);
      }
    });
    return nonClientFilterableKpis;
  },

  renderNonClientFilterableKpisWarningInfo(theme) {
    return (
        <span style={{color: theme.palette.warning.main}}>
                <i className="fa fa-exclamation-triangle" style={{paddingLeft: 5, paddingRight: 5}} />
                Non Client filterable Metric(s) selected &nbsp;
          <a onClick={this.openNonClientFilterableKpisInfoDialog}>(more info)</a>
            </span>);
  },

  openNonClientFilterableKpisInfoDialog() {
    this.setState({
      isNonClientFilterableKpisInfoDialogOpen: true
    });
  },

  closeNonClientFilterableKpisInfoDialog() {
    this.setState({
      isNonClientFilterableKpisInfoDialogOpen: false
    });
  },

  handleChangeKpis(kpiIds) {
    const allKpisAggregable = kpiIds.every(kpiId => kpiRepo.get(kpiId).get("trendDataAggregable"));
    this.setState((state) => {
      let config = state.config;
      if (!allKpisAggregable) {
        config = config.set("dataAggregation", "DAILY");
      }
      return {
        config: config.set("kpiIds", kpiIds)
      };
    })
  },

  handleChangeUser(userId) {
    const newConfig = this.state.config
        .set("qualifierType", "USER")
        .set("qualifierId", userId)
        .set("averageByUser", false);
    this.setState({
      config: newConfig
    });
  },

  handleChangeGroup(groupId) {
    const newConfig = this.state.config
        .set("qualifierType", "GROUP")
        .set("qualifierId", groupId);
    this.setState({
      config: newConfig
    });
  },

  onPlotChartClick() {
    const config = this.state.config;
    auditor.audit("charting:plot-standard", config.toJS());
    this.props.onPlotChartClick(config);
  },

  openNoKpisSelectedWarning() {
    this.setState({
      isNoKpisSelectedWarningOpen: true
    });
  },

  closeNoKpisSelectedWarning() {
    this.setState({
      isNoKpisSelectedWarningOpen: false
    });
  }

});

const fullWidth = {width: "100%"};

const spacer = {
  marginTop: "1rem",
  marginBottom: "1rem"
};

const getTrendableKpis = () => Immutable.fromJS(kpiRepo.getTrendableKpis());

const getRequiredTimeframes = () => {
  const singleDayTimeframes = new Set(["TODAY", "YESTERDAY"]);
  return timeframeRepo
      .getAll()
      .filter(timeframe => !singleDayTimeframes.has(timeframe.get("id").toUpperCase()));
};

const getTimeframe = timeframe => timeframeRepo.parse(timeframe.toJS());


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <StandardCharting theme={theme} {...props} />;
};

export default Wrapper;
