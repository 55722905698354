/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import getContainerDimensions from "react-dimensions";
import Tooltip from "js/common/views/tooltips";
import {isIE} from "js/common/utils/detect-browser";

import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import GroupAndUserPicker from "js/oneview/header/group-and-user-picker";
import FiltersDrawer from "js/common/views/filter-drawer/filters-drawer";
import Breadcrumbs from "js/common/views/breadcrumbs";
import {Column, Row} from "js/common/views/foundation-column-layout";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import currentClient from "js/common/repo/backbone/current-client";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import * as Auditor from "js/common/auditer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabScrollButton from "@mui/material/TabScrollButton";
import withStyles from "@mui/styles/withStyles";
import Cube19 from "js/cube19.app";
import {reactComponentToMarionetteComponent} from "js/common/views/react-to-marionette";
import AdminApp from "js/admin/app";
import FilterButton from "js/common/views/filter-drawer/filter-button";
import UserAvatar from "js/common/views/user-avatar";
import CompanyLogo from "js/common/views/company-logo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Styles from "js/oneview/header/header-container.styles";

const OneViewHeader = getContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    config: ImmutablePropTypes.map.isRequired,
    onConfigChange: ReactPropTypes.func.isRequired,
    onRefreshClick: ReactPropTypes.func.isRequired,
    onGroupClick: ReactPropTypes.func.isRequired,
    onUserClick: ReactPropTypes.func.isRequired,
    onFilterChanges: ReactPropTypes.func.isRequired,
    onJobPipelinesClick: ReactPropTypes.func,
    currentPageId: ReactPropTypes.number,
    isDataLoading: ReactPropTypes.bool,
    containerWidth: ReactPropTypes.number,
    pages: ImmutablePropTypes.list,
    clientFilterConfig: ImmutablePropTypes.map
  },

  getInitialState() {
    return ({isFiltersDrawerOpen: false});
  },

  getDefaultProps() {
    return {
      isDataLoading: false
    };
  },

  render() {
    const {
      config,
      onRefreshClick,
      onFilterChanges,
      isDataLoading,
      containerWidth,
      pages,
      currentPageId,
      appView,
      clientFilterConfig,
      theme
    } = this.props;
    const {palette} = theme;
    const client = currentClient;
    const userId = config.get("userId");
    const isUserHeader = !!userId;

    const displayName = isUserHeader ? Users.getUser(userId).get("fullName") : client.getClientName();
    const groupId = isUserHeader ? Users.getUser(userId).get("groupId") : config.get("groupId");

    const loggedInUser = Users.getCurrentUser();

    const canOnlySeeSelf = loggedInUser.get("dataVisibility") === "SELF";

    const pageOptions = pages.filter(page => !page.get("isDeleted") || page.get("id") === currentPageId)
        .sort((a, b) => a.get("name").localeCompare(b.get("name")))
        .sortBy(page => page.get("id") !== this.props.defaultPageId)
        .map(page => (Immutable.Map({label: page.get("name"), value: page.get("id")})));

    const browserIsIE = isIE();
    const smallScreenBreakpoint = 768;
    const mediumScreenBreakpoint = 1024;
    const buttonAndPickerContainerStyle = {
      paddingBottom: "1rem",
      paddingLeft: containerWidth < mediumScreenBreakpoint ? 0 : "0.5rem",
      paddingRight: 0,
      display: containerWidth > mediumScreenBreakpoint ? "inline-block" : "block",
      width: containerWidth > mediumScreenBreakpoint ? "50%" : "100%"
    };

    const headerStyle = {
      background: theme.themeId === "light" ? palette.background.card : "none",
      padding: 0,
      paddingTop: theme.themeId === "light" ? "2em" : 0,
      paddingLeft: containerWidth > 1024 ? "2.5em" : 0,
      paddingRight: containerWidth > 1024 ? "1.8em" : 0,
      position: "relative",
      bottom: theme.themeId === "light" ? "0" : "-2em",
      paddingBottom: theme.themeId === "light" ? "0" : "2em",
      maxWidth: theme.themeId === "light" ? "100%" : "90em",
      width: theme.themeId === "light" ? "100%" : "98%",
      boxShadow: theme.themeId === "light" ? "0px 2px 1px -1px rgba(0, 0, 0, 0.20)" : "none",
      marginBottom: theme.themeId === "light" ? "30px" : "0"
    };

    const displayPicStyle = {
      maxWidth: "10em",
      maxHeight: "6em",
      borderRadius: "4px"
    };

    const displayPicContainer = {
      width: "10em",
      height: "5em",
      marginRight: "1.8em",
      textAlign: "center",
      flexShrink: "0",
      display: containerWidth > 1024 ? "block" : "none"
    };

    const filtersStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    };

    const oneViewFiltersContainerStyle = {
      paddingLeft: containerWidth > 1024 ? "0.5rem" : 0,
      paddingRight: 0,
      marginBottom: "0.5rem",
      marginTop: "0.5em",
      display: "block",
      width: "100%"
    };

    const clientInfoStyle = {
      display: "flex",
      flexDirection: "row",
      borderRadius: "5px",
      paddingTop: "0.8em",
      marginLeft: containerWidth > "-0.9em" ? "0.9375em" : 0
    };

    const headingStyle = {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.themeId === "light" ? "500" : "300",
      display: "flex",
      fontSize: theme.themeId === "light" ? "22px" : "26px"
    };

    const tabScrollButton = withStyles(theme => ({
      root: {
        width: 14,
        marginTop: "15px",
        color: palette.primary.main,
        overflow: "hidden",
        transition: "width 0.5s",
        zIndex: "1 !important",
        "&.Mui-disabled": {
          color: palette.white,
          opacity: 0.4
        }
      }
    }))(TabScrollButton);

    return (
        <Row style={headerStyle}>
          <Column
              small={12}
              style={!Users.currentHasPermission("ONEVIEW_PAGE_SUPER_USER") || pageOptions.count() <= 1 ?
                  {position: "relative", paddingBottom: "25px", zIndex: 10} : {
                    position: "relative",
                    paddingBottom: "0.5em",
                    zIndex: 10
                  }}>
            <Row>
              <Column small={12} medium={7} large={8}>
                <Row style={{display: "flex", alignItems: "center", flexGrow: "1"}}>
                  <Column small={12} medium={12}>
                    <div style={clientInfoStyle}>
                      <div style={displayPicContainer}>
                        {isUserHeader
                            ? <UserAvatar user={Users.getUser(userId)} style={displayPicStyle} />
                            : <CompanyLogo client={client} style={displayPicStyle} />}
                      </div>

                      <div style={{display: "flex", flexDirection: "column", flexGrow: "1", justifyContent: "center"}}>
                        <h2 style={headingStyle}>
                          {loggedInUser.get("dataVisibility") !== "SELF" && appView === "user-oneview" &&
                              this.renderUserNavButtons(theme)}
                          {displayName}</h2>
                        <div style={{display: "flex", alignItems: "center", marginTop: "0.5em"}}>
                          {loggedInUser.get("dataVisibility") !== "SELF" ?
                              <GroupAndUserPicker
                                  onGroupClick={this.handleGroupClick}
                                  onUserClick={this.handleUserClick}
                                  containerWidth={containerWidth} /> :
                              <i className="bhi-group" style={{paddingLeft: 10, paddingRight: 10}} />}
                          <Breadcrumbs
                              currentGroup={Groups.getGroup(groupId)}
                              onUserClick={this.handleUserClick}
                              canOnlySeeSelf={canOnlySeeSelf}
                              breadcrumbs={Groups.getGroupBreadcrumbs(groupId)}
                              onClick={this.handleGroupClick}
                              isDisabled={canOnlySeeSelf}
                              showDividerAfterLastItem={!canOnlySeeSelf} />
                        </div>
                      </div>
                    </div>
                  </Column>
                </Row>
              </Column>
              <Column
                  small={12}
                  medium={5}
                  large={4}
                  style={{
                    paddingRight: containerWidth > 1024 ? "0" : "0.5rem",
                    position: "relative",
                    bottom: "-5px"
                  }}>
                {clientHasPermission() &&
                    <div style={oneViewFiltersContainerStyle}>
                      <FilterButton
                          openFiltersDrawer={this.openFiltersDrawer}
                          clientFilterConfig={clientFilterConfig}
                          config={config}
                          isFiltersDrawerOpen={this.state.isFiltersDrawerOpen} />
                      <FiltersDrawer
                          open={this.state.isFiltersDrawerOpen}
                          onRequestOpen={this.openFiltersDrawer}
                          onRequestClose={this.closeFiltersDrawer}
                          config={config}
                          clientFilterConfig={clientFilterConfig}
                          onFilterChanges={onFilterChanges}
                      />
                    </div>
                }
                <div style={filtersStyle}>
                  <div style={{...buttonAndPickerContainerStyle, width: "3em", paddingRight: "0", minWidth: "32px"}}>
                    <TextButton
                        icon="refresh"
                        iconType="bhi"
                        label=""
                        disabled={isDataLoading}
                        theme="default"
                        fullWidth={true}
                        extraStyle={{
                          background: theme.themeId === "light"
                              ? palette.background.paper
                              : palette.background.card,
                          color: palette.text.main,
                          height: 35,
                          minWidth: 35,
                          paddingLeft: "0",
                          paddingRight: "0",
                          boxShadow: "none"
                        }}
                        onClick={onRefreshClick} />
                  </div>
                  <div
                      style={{
                        ...buttonAndPickerContainerStyle,
                        width: containerWidth > smallScreenBreakpoint ? "100%" : "90%"
                      }}>
                    <TimeframePicker
                        timeframe={config.get("timeframe")}
                        onChange={this.handleTimeframeChange}
                        isDisabled={isDataLoading} />
                  </div>
                </div>
              </Column>
            </Row>
          </Column>
          {Users.currentHasPermission("ONEVIEW_PAGE_SUPER_USER")
          && pageOptions.count() > 1
              ?
              <Column small={12}>
                <Row>
                  <div css={Styles.appBarStyle(palette, theme.themeId)}>
                    <div data-test-id="home-button" style={{marginTop: "15px", marginRight: "5px"}}>
                      <Tooltip
                          text={!config.get("pageId") ? "Already viewing default page" :
                              "Return to default view"} position="right">
                        <IconButton
                            onClick={this.handleDefaultPageClick}
                            icon="home"
                            iconType="bhi"
                            disableClick={!config.get("pageId")}
                            size="large" />
                      </Tooltip>
                    </div>
                    <Tabs
                        // 'no selected tab' must be false or material UI throws warnings
                        value={currentPageId ?? false}
                        variant="scrollable"
                        scrollButtons
                        ScrollButtonComponent={tabScrollButton}
                        onChange={this.handlePageSelect}
                        aria-label="OneView tabs"
                        indicatorColor="primary"
                        style={{width: "100%"}}>
                      {pageOptions.map((option, index) => {
                            const isDefault = option.get("value") === this.props.defaultPageId;
                            const name = option.get("label");
                            const formattedName = name.length > 20 ? name.substring(0, 20) + "..." : name;
                            const label = <Tooltip
                                text={name}
                                position="top"
                                disable={name.length < 21}
                                customStyle={{zIndex: 9999}}
                            >
                              <div style={{textTransform: "none"}}>
                                {isDefault && <i className="bhi-columns" style={{paddingRight: "5px"}} />}
                                {formattedName}
                              </div>
                            </Tooltip>;
                            const title = (browserIsIE && name.length > 21) ? name : "";

                            return <Tab
                                data-test-id={"oneview-tab-" + index}
                                label={label}
                                title={title}
                                value={option.get("value")}
                                css={Styles.tabStyles(palette, theme.themeId)}
                                key={option.get("value")} />;
                          }
                      )}
                      {userCanAccess("SECTION_ADMIN") && <Tab
                          className="add-new-oneview"
                          label="+"
                          value="ADD_ONEVIEW_PAGE"
                          css={Styles.tabStyles(palette, theme.themeId)}
                          style={{opacity: "40%", minWidth: 50}} />}
                    </Tabs>
                  </div>
                </Row>
              </Column>
              : <div style={{paddingBottom: 100}}></div>}
        </Row>
    );
  },

  gotoOneviewAdminApp() {
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(
        <AdminApp initialPage="SECTION_ADMIN" />
    ));
  },

  renderUserNavButtons(theme) {
    const buttonStyle = {
      background: "transparent",
      padding: 0,
      width: "33px"
    };
    const iconStyle = {
      fontSize: "36px",
      color: theme.palette.textColor
    };

    return (
        <div
            style={{
              top: "-3px",
              display: "flex",
              marginRight: "10px",
              position: "relative"
            }}>
          <Tooltip text="Previous User" position={theme.themeId === "light" ? "bottom" : "top"}>
            <IconButton
                hoverType="default"
                id="prev-user"
                style={buttonStyle}
                icon="angle-left"
                iconStyle={iconStyle}
                onClick={() => this.props.onUserNavClick("prev-user")}
                size="large" />
          </Tooltip>
          <Tooltip text="Next User" position={theme.themeId === "light" ? "bottom" : "top"}>
            <IconButton
                hoverType="default"
                id="next-user"
                style={buttonStyle}
                icon="angle-right"
                iconStyle={iconStyle}
                onClick={() => this.props.onUserNavClick("next-user")}
                size="large" />
          </Tooltip>
        </div>
    );
  },

  handleGroupClick(groupId) {
    const loggedInUser = Users.getCurrentUser();
    if (loggedInUser.get("dataVisibility") === "SELF") {
      return;
    }
    this.props.onGroupClick(groupId);
  },

  handleUserClick(userId) {
    const loggedInUser = Users.getCurrentUser();
    const isNotLoggedInUser = userId !== loggedInUser.get("id");
    if (isNotLoggedInUser && loggedInUser.get("dataVisibility") === "SELF") {
      return;
    }
    this.props.onUserClick(userId);
  },

  handleTimeframeChange(timeframe) {
    const {config, onConfigChange} = this.props;
    onConfigChange(config.set("timeframe", timeframe));
  },

  handleDefaultPageClick() {
    const {config, onConfigChange} = this.props;
    onConfigChange(config.delete("pageId"));
  },

  handlePageSelect(event, newValue) {
    const {config, onConfigChange} = this.props;
    if (newValue === "ADD_ONEVIEW_PAGE") {
      this.gotoOneviewAdminApp();
    } else {
      Auditor.audit("oneview:page-select", {
        userId: config.get("userId"),
        groupId: config.get("groupId"),
        newValue
      });
      onConfigChange(config.set("pageId", newValue));
    }
  },

  openFiltersDrawer() {
    this.setState({
      isFiltersDrawerOpen: true
    });
  },

  closeFiltersDrawer() {
    this.setState({
      isFiltersDrawerOpen: false
    });
  }

}));

const clientHasPermission = () => {
  return currentClient.canAccessApp("TAGS_ADMIN") || currentClient.hasPermission("ORGANISATION_FILTER");
};

const userCanAccess = app => {
  const user = Users.getCurrentUser();
  return Users.canAccessApp(user, app);
};

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <OneViewHeader theme={theme} {...props} />;
};

export default Wrapper;
