import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import Tooltip from "react-tooltip";
import DropdownToggle from "js/common/views/react-dropdown-toggle";

import {TextButton} from "js/common/views/inputs/buttons";
import {Row, Column} from "js/common/views/foundation-column-layout";
import currentClient from "js/common/repo/backbone/current-client";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import {TextField} from "@mui/material";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";

const KpiPicker = createReactClass({

  displayName: "KpiPicker",

  mixins: [PureRenderMixin],

  propTypes: {
    kpisById: ImmutablePropTypes.map.isRequired,
    selectedKpiIds: ImmutablePropTypes.set.isRequired,
    onKpiSelectionChange: ReactPropTypes.func.isRequired,
    isLoadingKpis: ReactPropTypes.bool,
    isDisabled: ReactPropTypes.bool,
    disableNonClientFilterableKpis: ReactPropTypes.bool
  },

  getInitialState() {
    return {
      isOpen: false,
      searchFilter: ""
    };
  },

  render() {
    const {
      selectedKpiIds,
      isLoadingKpis,
      isDisabled,
      disableNonClientFilterableKpis,
      fullTheme
    } = this.props;
    const {searchFilter, isOpen} = this.state;
    return (
        <DropdownToggle
            primaryText={this.getDisplayLabel()}
            isDisabled={isLoadingKpis || isDisabled}
            isOpen={isOpen}
            onToggleButtonClick={this.toggleDropdown}
            onRequestClose={this.closeDropdown}>
          <KpiList
              searchFilter={searchFilter}
              kpis={this.getVisibleKpis()}
              selectedKpiIds={selectedKpiIds}
              disableNonClientFilterableKpis={disableNonClientFilterableKpis}
              onKpiSelectionChange={this.handleKpiSelectionChange}
              onSearchFilterChange={this.handleKpiSearchFilterChange}
              onDoneClick={this.closeDropdown}
              onSelectAllClick={this.selectAllKpis}
              onDeselectAllClick={this.deselectAllKpis}
              theme={fullTheme} />
        </DropdownToggle>);
  },

  getVisibleKpis() {
    const searchString = this.state.searchFilter.toLowerCase();
    const kpis = this.props.kpisById
        .toList()
        .filter(kpi => {
          const kpiId = kpi.get("id");
          return KpiRepo.get(kpiId) !== undefined;
        })
        .sortBy(kpi => kpi.get("name").toLowerCase());
    if (searchString.length > 0) {
      return kpis.filter(kpi => kpi.get("name").toLowerCase().includes(searchString));
    } else {
      return kpis;
    }
  },

  getDisplayLabel() {
    if (this.props.isLoadingKpis) {
      return "Loading Metrics...";
    } else {
      const selectedKpiIdsCount = this.props.selectedKpiIds.count();
      switch (selectedKpiIdsCount) {
        case 0:
          return "Select Metrics for Report";
        case 1:
          return "1 Metric Selected";
        default:
          return `${selectedKpiIdsCount} Metrics Selected`;
      }
    }
  },

  toggleDropdown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  },

  closeDropdown() {
    this.setState({
      isOpen: false,
      searchFilter: ""
    });
  },

  handleKpiSearchFilterChange(searchFilter) {
    this.setState({
      searchFilter
    });
  },

  handleKpiSelectionChange(kpiId) {
    const {selectedKpiIds, onKpiSelectionChange} = this.props;
    const newSelectedKpiIds = selectedKpiIds.includes(kpiId) ? selectedKpiIds.delete(kpiId) : selectedKpiIds.add(kpiId);
    onKpiSelectionChange(newSelectedKpiIds.toList());
  },

  selectAllKpis() {
    const {selectedKpiIds, onKpiSelectionChange} = this.props;
    const isSearchFilterActive = this.state.searchFilter.length > 0;
    const visibleKpiIds = this.getVisibleKpis()
        .map(k => k.get("id"))
        .toSet();
    const newSelectedKpiIds = isSearchFilterActive ? selectedKpiIds.union(visibleKpiIds) : visibleKpiIds;
    onKpiSelectionChange(newSelectedKpiIds.toList());
  },

  deselectAllKpis() {
    const {selectedKpiIds, onKpiSelectionChange} = this.props;
    const isSearchFilterActive = this.state.searchFilter.length > 0;
    const visibleKpiIds = this.getVisibleKpis()
        .map(k => k.get("id"))
        .toSet();
    const newSelectedKpiIds = isSearchFilterActive ? selectedKpiIds.subtract(visibleKpiIds) : selectedKpiIds.clear();
    onKpiSelectionChange(newSelectedKpiIds.toList());
  }

});


const KpiList = createReactClass({

  mixins: [PureRenderMixin],

  componentDidMount() {
    this.timeout = setTimeout(() => this.searchTextInput.focus(), 250);
  },

  componentWillUnmount() {
    clearTimeout(this.timeout);
  },

  render() {
    const {
      onSelectAllClick,
      onDeselectAllClick,
      onDoneClick,
      kpis,
      selectedKpiIds,
      disableNonClientFilterableKpis,
      onKpiSelectionChange,
      searchFilter,
      onSearchFilterChange,
      theme
    } = this.props;

    const listStyle = (theme) => ({
      listStyleType: "none",
      fontSize: "0.8rem",
      lineHeight: 1,
      marginBottom: 0,
      maxHeight: "350px",
      overflow: "auto",
      color: theme.palette.text.main
    });

    const buttonStyle = {
      marginLeft: 5,
      marginRight: 5
    };
    return (
        <div style={{padding: "0.5rem 0.875rem", color: theme.palette.textColor}}>
          <Row collapse={true}>
            <Column small={12} smallCentered={true} style={{marginBottom: "0.5rem"}}>
              <TextButton label="Select All" style={buttonStyle} onClick={onSelectAllClick} />
              <TextButton label="Deselect All" style={buttonStyle} onClick={onDeselectAllClick} />
              <TextButton label="Done" style={buttonStyle} onClick={onDoneClick} />
            </Column>
          </Row>
          <TextField
              variant="standard"
              label="Type to search for Metrics"
              value={searchFilter}
              style={{width: 200}}
              onChange={e => onSearchFilterChange(e.target.value)}
              inputProps={{"data-test-id": "kpi-picker-search"}}
              inputRef={ref => this.searchTextInput = ref} />
          <ul style={listStyle(theme)}>
            {kpis
                .filter(kpi => !kpi.get("deleted") || selectedKpiIds.includes(kpi.get("id")))
                .map(kpi => (
                <li key={kpi.get("id")} style={{listStyleType: "none", padding: "5px 0"}}>
                  <KpiOption
                      theme={theme}
                      kpi={kpi}
                      selected={selectedKpiIds.includes(kpi.get("id"))}
                      disableNonClientFilterableKpis={disableNonClientFilterableKpis}
                      onKpiSelectionChange={onKpiSelectionChange} />
                </li>
            ))}
          </ul>
        </div>
    );
  }

});

const KpiOption = pure(({kpi, selected, disableNonClientFilterableKpis, onKpiSelectionChange, theme}) => {
  const isKpiNotFilterableByClient = !kpi.get("filterableByClient");
  const client = currentClient;
  const isKpiOptionDisabled = client.hasPermission("ORGANISATION_FILTER") &&
      isKpiNotFilterableByClient &&
      disableNonClientFilterableKpis;
  const labelStyle = {
    fontSize: "0.9rem",
    marginBottom: 0,
    color: isKpiOptionDisabled ? "#999" : theme.palette.text.main,
    cursor: isKpiOptionDisabled ? "not-allowed" : "pointer"
  };
  return (
      <label
          data-test-id="kpi-option"
          style={labelStyle}
          data-for={isKpiNotFilterableByClient ? "kpi-warning" : ""}>
        <input
            type="checkbox"
            name={kpi.get("name")}
            value={kpi.get("id")}
            checked={selected}
            disabled={isKpiOptionDisabled}
            onChange={e => {
              const kpiId = parseInt(e.target.value, 10);
              onKpiSelectionChange(kpiId);
            }} />
        <span style={{marginLeft: 5, marginRight: 5}}>{kpi.get("name")}</span>
        {isKpiNotFilterableByClient &&
            <i className="fa fa-exclamation-triangle" style={{color: theme.palette.warning.main}} />}
        {isKpiNotFilterableByClient &&
            <Tooltip id="kpi-warning" place="top" type={theme.themeId === "light" ? "dark" : "light"} effect="solid">
              <i className="fa fa-exclamation-triangle" /> Metric not filterable by client
            </Tooltip>}
      </label>
  );

});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <KpiPicker fullTheme={theme} {...props} />;
};
