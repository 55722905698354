import React from "react";
import Immutable from "immutable";

import {TextButton} from "js/common/views/inputs/buttons";
import currentClient from "js/common/repo/backbone/current-client";
import {toCommaSeparatedWithAnd} from "js/common/utils/strings";
import Tooltip from "js/oneview/header/tooltip";
import {Chip} from "@mui/material";
import * as Colors from "js/common/cube19-colors";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCity, faBuilding, faTags} from "@fortawesome/pro-regular-svg-icons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const FilterButton = props => {
  const {
    openFiltersDrawer,
    clientFilterConfig = Immutable.Map(),
    config,
    isFiltersDrawerOpen,
    style,
  } = props;

  let styling = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    fontSize: "13px",
    fontWeight: 400
  };
  let [filterNames, setFilterNames] = React.useState(Immutable.Map());
  const {theme} = React.useContext(CustomThemeContext);

  const filteredClients = clientFilterConfig.get("clientIds", Immutable.List());
  const filteredClientSets = clientFilterConfig.get("clientSetIds", Immutable.List());
  const hasTagsAdmin = currentClient.canAccessApp("TAGS_ADMIN");
  const hasOrganisationFilterPermission = currentClient.hasPermission("ORGANISATION_FILTER");

  const excludedTags = config.get("excludedTagIds", Immutable.List());
  const matchAllTags = config.get("matchAllTagIds", Immutable.List());
  const matchAnyTags = config.get("matchAnyTagIds", Immutable.List());

  const tagsCount = excludedTags.concat(matchAnyTags).concat(matchAllTags).size;

  const newSummaryText = getSummaryText(filteredClients, filteredClientSets, tagsCount, hasTagsAdmin, hasOrganisationFilterPermission);

  const filterTooltipContent = <PreviewTooltipContent
      filterNames={filterNames}
      matchAllTagIds={matchAllTags}
      matchAnyTagIds={matchAnyTags}
      excludedTagIds={excludedTags}
      clientIds={filteredClients}
      clientSetIds={filteredClientSets} />;


  const showTooltip = tagsCount > 0 || filteredClients.size > 0 || filteredClientSets.size > 0;
  if (showTooltip) {
    styling = {...styling, color: theme.palette.primary.main};
  }

  const filterSummary = (showTooltip && !isFiltersDrawerOpen)
      ? <Tooltip
          typeToIdToNamedItem={filterNames}
          setNames={setFilterNames}
          content={filterTooltipContent}
          selectedClientIds={filteredClients}
          selectedClientSetIds={filteredClientSets}>
        <div style={{...styling, display: "flex"}}>
          <div style={{
            display: "block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            fontSize: 13,
            minWidth: 0,
            fontWeight: 400
          }}>{newSummaryText}</div>
        </div>
      </Tooltip>
      : <div style={styling}>{newSummaryText}</div>;

  const filtersButtonStyle = {
    height: 37,
    minWidth: 35,
    paddingLeft: "0.875em",
    paddingRight: "0.875em",
    justifyContent: "flex-start",
    textAlign: "left",
    textTransform: "none",
    fontSize: "15px",
    overflow: "visible",
    boxShadow: "none",
    background: theme.themeId === "light" ? Colors.white : Colors.charade,
    border: `2px solid ${theme.themeId === "light" ? "#ddd" : Colors.charade}`
  };

  const iconColor = theme.themeId === "light" ? "#000" : Colors.white;
  const suffixIconColor = theme.themeId === "light" ? "#000" : Colors.white;

  return (
      <TextButton
          className="filter-tags-client"
          icon="filter"
          iconType="bhi"
          iconStyle={{color: iconColor}}
          suffixIconStyle={{color: suffixIconColor}}
          suffixIcon="edit"
          type="default"
          label={filterSummary}
          labelStyle={{width: "90%"}}
          fullWidth={true}
          extraStyle={{...filtersButtonStyle, ...style}}
          onClick={openFiltersDrawer} />
  );
};

export const PreviewTooltipContent = React.memo(({
  clientIds,
  clientSetIds,
  matchAnyTagIds,
  matchAllTagIds,
  excludedTagIds,
  filterNames,
  hasShowAll,
  maxWidth = 400,
  invertTagColours = false
}) => {
  const tagsCount = excludedTagIds.size + matchAnyTagIds.size + matchAllTagIds.size;
  const [shouldShowAll, setShouldShowAll] = React.useState(false);

  const hasTagsAdmin = currentClient.canAccessApp("TAGS_ADMIN");
  const hasOrganisationFilterPermission = currentClient.hasPermission("ORGANISATION_FILTER");

  const allTags = Immutable.fromJS([
    {category: "Any", ids: matchAnyTagIds, color: "#E1FFB0", categoryToNames: getTagNames(matchAnyTagIds, filterNames)},
    {category: "All", ids: matchAllTagIds, color: "#B0ECFF", categoryToNames: getTagNames(matchAllTagIds, filterNames)},
    {
      category: "Exclude",
      ids: excludedTagIds,
      color: "#FFCDB0",
      categoryToNames: getTagNames(excludedTagIds, filterNames)
    }
  ]);

  const tooltipComponents = getTooltipComponents(
      clientIds,
      clientSetIds,
      tagsCount,
      allTags,
      hasTagsAdmin,
      hasOrganisationFilterPermission,
      hasShowAll);
  const {theme} = React.useContext(CustomThemeContext);
  if (tooltipComponents.size > 0 || tagsCount > 0) {
    return <div style={{padding: "5px 5px 0px 0px"}}>
      {tooltipComponents.map(component =>
          <div style={{paddingBottom: "10px", paddingTop: "5px"}} key={component.get("category")}>
            <div style={{paddingBottom: "5px"}}>
              <FontAwesomeIcon icon={icons[component.get("category")]} size="lg" style={{paddingRight: "5px"}} />
              <b>{component.get("title")}</b>
            </div>
            {component.get("category") === "TAGS"
                ? component.get("tagGroups").map(group => {
                  if (group.get("ids").size > 0) {
                    return (<div
                        key={group.get("category")}
                        style={{display: "flex", flexDirection: "column", paddingTop: "5px"}}>
                      <Chip
                          size="small"
                          label={group.get("category")}
                          variant="standard"
                          style={{
                            background: group.get("color"),
                            color: "rgba(0, 0, 0, 0.5)",
                            display: "inline-block",
                            marginRight: 15,
                            width: "60px",
                            textAlign: "center",
                            marginBottom: 10,
                            height: 20,
                            lineHeight: "20px",
                            fontSize: "0.7rem"
                          }} />
                      <div style={{display: "flex", minWidth: 220, maxWidth: maxWidth, flexWrap: "wrap"}}>
                        {group.get("categoryToNames").map((tagNames, category) => <div key={category} style={{
                          backgroundColor: invertTagColours ? theme.palette.background.invertedChip : theme.palette.background.paper,
                          marginRight: 10,
                          padding: "2px 10px",
                          borderRadius: 10,
                          marginBottom: 10,
                          fontSize: "0.75rem",
                          maxWidth: maxWidth,
                          display: "flex",
                          flexWrap: "wrap"
                        }}>
                          <span style={{color: "#888", overflowWrap: "break-word", display: "inline-block"}}>{category}:&nbsp;</span>
                          <span style={{display: "inline-block"}}>{tagNames.join(", ")}</span>
                        </div>).valueSeq()}
                      </div>
                    </div>);
                  } else {
                    return null;
                  }
                })
                : <>
                  {component.get("ids").take(shouldShowAll ? component.get("ids").size : 6).map(id => {
                    return <div key={id} style={{textOverflow: "ellipsis", overflow: "hidden"}}>
                      <i className="fa fa-circle" style={{color: theme.palette.primary.main, fontSize: "0.75em"}} />
                      <span style={{paddingLeft: "5px", paddingRight: "5px"}}>
                        {filterNames.size > 0 && filterNames.get(component.get("category")).size > 0 &&
                            filterNames.get(component.get("category")).get(id, Immutable.Map()).get("name")}
                      </span>
                    </div>;
                  })}
                  {!shouldShowAll && component.get("ids").size > 6 &&
                      <a onClick={() => hasShowAll && setShouldShowAll(true)} style={{color: theme.palette.primary.main}}>+ {component.get("ids").size - 6} more</a>}
                </>
            }
          </div>
      )}
    </div>;
  } else {
    return null;
  }
});

const getTagNames = (ids, filterNames) => {
  if (filterNames.get("TAGS")) {
    const filteredTags = filterNames.get("TAGS").filter(tag => ids.includes(tag.get("id"))).valueSeq().toList();
    const groupedTags = filteredTags.groupBy(tag => tag.get("category"));
    return groupedTags.map(category => category.map(tag => tag.get("name")));
  } else {
    return Immutable.List();
  }
};

const icons = {
  CLIENTS: faBuilding,
  CLIENT_SETS: faCity,
  TAGS: faTags
};

const getSummaryText = (filteredClients, filteredClientSets, tagsCount, hasTagsAdmin, hasOrganisationFilterPermission) => {
  let filterSummaryComponents = Immutable.List();

  if (!filteredClients.isEmpty() && hasOrganisationFilterPermission) {
    filterSummaryComponents =
        filterSummaryComponents.push(`(${filteredClients.size}) client${filteredClients.size > 1 ? "s" : ""}`);
  }

  if (!filteredClientSets.isEmpty() && hasOrganisationFilterPermission) {
    filterSummaryComponents =
        filterSummaryComponents.push(`(${filteredClientSets.size}) client set${filteredClientSets.size > 1 ? "s" :
            ""}`);
  }

  if (tagsCount > 0 && hasTagsAdmin) {
    filterSummaryComponents = filterSummaryComponents.push(`(${tagsCount}) tag${tagsCount > 1 ? "s" : ""}`);
  }

  if (!filterSummaryComponents.isEmpty()) {
    return "Filtered by " + toCommaSeparatedWithAnd(filterSummaryComponents);
  } else {
    return `Filter by${hasTagsAdmin ? " Tags" : " Clients"}
    ${hasTagsAdmin && hasOrganisationFilterPermission ? " or Clients" : ""}`;
  }
};

const getTooltipComponents = (
    filteredClients,
    filteredClientSets,
    tagsCount,
    allTags,
    hasOrganisationFilterPermission,
    hasTagsAdmin
) => {
  let filterTooltipComponents = Immutable.List();

  if (!filteredClients.isEmpty() && hasOrganisationFilterPermission) {  //use ! is Empty
    filterTooltipComponents = filterTooltipComponents.push(Immutable.Map({
      category: "CLIENTS",
      title: `Clients (${filteredClients.size})`,
      ids: filteredClients
    }));
  }

  if (!filteredClientSets.isEmpty() && hasOrganisationFilterPermission) {
    filterTooltipComponents = filterTooltipComponents.push(Immutable.Map({
      category: "CLIENT_SETS",
      title: `Client Sets (${filteredClientSets.size})`,
      ids: filteredClientSets
    }));
  }

  if (tagsCount > 0 && hasTagsAdmin) {
    filterTooltipComponents = filterTooltipComponents.push(Immutable.Map({
      category: "TAGS",
      title: `Tags (${tagsCount})`,
      tagGroups: allTags
    }));
  }

  return filterTooltipComponents;

};

export default FilterButton;
