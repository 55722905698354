import React from "react";
import ReactPropTypes from 'prop-types';
import createReactClass from "create-react-class";
import { TextButton } from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import { TextField } from '@mui/material';

const blankNameError = "The name cannot be blank";
const maxNameLength = 64;

export default createReactClass({

    propTypes: {
        onSaveClick: ReactPropTypes.func.isRequired,
        onCancelClick:  ReactPropTypes.func.isRequired,
        isOpen: ReactPropTypes.bool.isRequired
    },

    getInitialState() {
        return {
            pageName: "",
            errorText: ""
        };
    },

    render() {
        const actions = [
            <TextButton
                key="cancel"
                label="Cancel"
                icon="history"
                style={{marginRight: ".5em"}}
                onClick={this.handleCancelClick} />,
            <TextButton
                key="save"
                label="Save"
                icon="floppy-o"
                style={{marginLeft: ".5em"}}
                onClick={this.handleSaveClick} />];
        return (
            <Dialog
                title="Save current page as..."
                actions={actions}
                autoDetectWindowHeight={true}
                titleStyle={{ color: "#f9ec33", fontSize: "1rem" }}
                open={this.props.isOpen}>
                {/* this is a stopgap to fix a known issue with React and/or Material-UI for IE11
                    https://github.com/facebook/react/issues/6822 */}
                <style type="text/css" dangerouslySetInnerHTML={{__html: "::-ms-clear {display: none;}"}} />
                <TextField variant="standard"
                    data-test-id="save-current-charting-page-as"
                    style={{width: 300}}
                    label="Give this set of charts a name"
                    error={!!this.state.errorText}
                    helperText={this.state.errorText}
                    value={this.state.pageName}
                    onChange={this.updatePageName}
                    onKeyDown={this.handleOnKeyDown} />
            </Dialog>);
    },

    handleOnKeyDown(e) {
        if (e.key === "Enter") {
            this.triggerSaveCallback();
        }
    },

    handleCancelClick() {
        this.setState(this.getInitialState());
        this.props.onCancelClick();
    },

    updatePageName(e) {
        this.setState({
            pageName: e.target.value.substring(0, maxNameLength),
            errorText: e.target.value.length === 0 ? blankNameError : ""
        });
    },

    handleSaveClick() {
        this.triggerSaveCallback();
    },

    triggerSaveCallback() {
        if (this.state.pageName.length === 0) {
            this.setState({
                errorText: blankNameError
            })
            return;
        }
        this.props.onSaveClick(this.state.pageName);
    }

});
