import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import {faBuilding, faCity} from "@fortawesome/pro-regular-svg-icons";
import memoize from "memoize-immutable";

import InfiniteListSelect from "js/common/views/infinite-loading/infinite-list-select";
import ClientSetsDrawer from "js/common/views/client-filter/client-sets-list";
import ErrorMsg from "js/common/views/error";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Ajax from "js/common/ajax";
import * as SavedConfigs from "js/common/saved-configs";
import * as Popups from "js/common/popups";
import * as Users from "js/common/users";
import {indexBy} from "js/common/utils/collections";

export default createReactClass({

  getInitialState() {
    return {
      selectedClientItems: Immutable.Set(),
      selectedClientSetItems: Immutable.Set(),
      ownershipTypeToSavedClientSets: Immutable.Map(),
      loadingSavedClientSets: true
    };
  },

  getDefaultProps() {
    return {
      selectedClientIds: Immutable.Set(),
      selectedClientSetIds: Immutable.Set(),
    };
  },

  componentDidMount() {
    this.loadAndSetClientData(this.props.selectedClientIds, this.props.selectedClientSetIds);
  },

  componentDidUpdate(prevProps) {
    if (!Immutable.is(prevProps.selectedClientIds, this.props.selectedClientIds) ||
        !Immutable.is( prevProps.selectedClientSetIds, this.props.selectedClientSetIds)) {
      this.loadAndSetClientData(this.props.selectedClientIds, this.props.selectedClientSetIds);
    }
  },

  loadAndSetClientData(selectedClientIds, selectedClientSetIds) {
    const promises = [loadClientSets(), loadInitialItems(selectedClientIds)];
    Promise.all(promises)
        .then(([ownershipTypeToSavedClientSets, items]) => {
          const selectedClientSets = toClientSetItems(ownershipTypeToSavedClientSets)
              .filter(clientSet => selectedClientSetIds.includes(clientSet.get("clientSetId")))
              .toSet();
          const idToClientSet = indexBy(cs => cs.get("clientSetId"), selectedClientSets);
          const clientSetIdsNotVisible = selectedClientSetIds
              .toSet()
              .subtract(idToClientSet.keySeq().toSet());
          const idToNamePlaceholder = indexBy(x => x, clientSetIdsNotVisible).map(id => {
            return Immutable.Map({
              clientSetId: id,
              name: "[this client set has not been shared with you]",
              type: "CLIENT_SET",
              id: "CLIENT_SET_" + id,
              clientCount: 0
            });
          });
          this.setState({
            selectedClientItems: items,
            selectedClientSetItems: idToClientSet.merge(idToNamePlaceholder).valueSeq().toSet(),
            ownershipTypeToSavedClientSets,
            loadingSavedClientSets: false
          });
        }, () => {
          this.setState({
            loadingSavedClientSets: false
          });
        });
  },

  render() {
    const {
      selectedClientItems,
      selectedClientSetItems,
      ownershipTypeToSavedClientSets,
      loadingSavedClientSets,
      manageClientSets,
      newClientSet
    } = this.state;
    const placeholder = loadingSavedClientSets
        ? "Loading..."
        : this.props.customPlaceholder || <span>
          <i className="bhi-filter" style={{marginLeft: "2px", marginRight: "8px", marginTop: "-1px"}} />
          Filter by Client
        </span>;
    const buttonStyle = {marginLeft: "0.25rem", marginRight: "0.25rem"};
    const menuFooterCreatorFn = (props) => <div style={{
      marginTop: "1rem",
      marginBottom: "1rem",
      padding: "0 1rem",
      textAlign: "center"
    }}>
      {this.hasChanges() && <ErrorMsg type="warn" text="Client filter change not yet applied." />}
      <TextButton
          type="default"
          label="Manage Client Sets"
          testId="manage-client-set-button"
          style={buttonStyle}
          onClick={() => {
            props.closeMenu();
            this.handleManageClientSetsClick();
          }} />
      <TextButton
          label="Create Client Set"
          style={buttonStyle}
          onClick={() => {
            props.closeMenu();
            this.handleCreateClientSetClick();
          }} />
    </div>;
    const clientSets = toClientSetItems(ownershipTypeToSavedClientSets)
        .sortBy(item => item.get("name").toLowerCase(), (a, b) => a.localeCompare(b));
    const selectedItems = selectedClientSetItems
        .concat(selectedClientItems)
        .sortBy(item => {
          const name = item.get("name");
          return name.substring(name.indexOf("-")).toLowerCase();
        }, (a, b) => a.localeCompare(b));
    return <div data-test-id="client-filters-drawer-dropdown">
      <InfiniteListSelect
          onOpenStateChange={this.props.onOpenStateChange}
          showSelectedInDropdown={this.props.showSelectedInDropdown}
          searchUrl={"entity-finder/search"}
          searchParams={{entity: "CLIENT"}}
          searchResultToItem={searchResultToItem}
          placeholder={placeholder}
          selectedItems={selectedItems}
          bgColor="paper"
          itemIcon={faBuilding}
          startItems={clientSets}
          startItemIcon={faCity}
          menuFooterCreatorFn={menuFooterCreatorFn}
          onChange={this.handleItemsChange}
          onRequestClose={this.onClose}
          disabled={loadingSavedClientSets || this.props.isDisabled}
          dataTestId={"client-filter"}/>
      <ClientSetsDrawer
          isOpen={!!manageClientSets}
          clientSet={newClientSet}
          ownershipTypeToSavedClientSets={ownershipTypeToSavedClientSets}
          onRefreshClientSets={this.refreshClientSets}
          onAddClientSet={this.handleAddClientSet}
          onUpdateClientSet={this.handleUpdateClientSet}
          onRemoveClientSet={this.handleRemoveClientSet}
          loading={loadingSavedClientSets}
          onRequestOpen={this.handleManageClientSetsClick}
          onRequestClose={this.closeClientSetsDialog} />
    </div>;
  },

  handleItemsChange(items) {
    const { flushChangesImmediately } = this.props;
    const filteredItems = items.filter(item => item.get("type") === "CLIENT_SET");
    const selectedClientSetItems = filteredItems.toSet();
    const selectedClientItems = items.subtract(selectedClientSetItems);

    this.setState({
      selectedClientItems,
      selectedClientSetItems
    }, () => {
      if (flushChangesImmediately) {
        const { selectedClientItems, selectedClientSetItems, ownershipTypeToSavedClientSets } = this.state;
        this.handleChange(selectedClientItems, selectedClientSetItems, ownershipTypeToSavedClientSets);
      }
    });
  },

  onClose() {
    if (this.hasChanges()) {
      const {ownershipTypeToSavedClientSets, selectedClientItems, selectedClientSetItems} = this.state;
      this.handleChange(selectedClientItems, selectedClientSetItems, ownershipTypeToSavedClientSets);
    }
  },

  handleAddClientSet(newClientSet) {
    const replaceFilter = newClientSet.get("replaceFilter");
    return SavedConfigs
        .create(newClientSet.delete("replaceFilter"))
        .then(clientSetConfig => {
          const clientSet = toClientSet(clientSetConfig);
          const ownershipTypeToSavedClientSets = this.state.ownershipTypeToSavedClientSets
              .update("ownedConfigs", list => list.push(clientSet));
          if (replaceFilter) {
            const selectedClientItems = Immutable.Set();
            const selectedClientSetItems = Immutable.Set().add(clientSetToItem(clientSet));
            this.setState({
              ownershipTypeToSavedClientSets,
              selectedClientItems,
              selectedClientSetItems
            });
            this.handleChange(selectedClientItems, selectedClientSetItems, ownershipTypeToSavedClientSets);
          } else {
            this.setState({ownershipTypeToSavedClientSets});
          }
          return clientSet;
        });
  },

  handleUpdateClientSet(changedClientSet) {
    return SavedConfigs
        .update(changedClientSet)
        .then(clientSetConfig => {
          const {ownershipTypeToSavedClientSets, selectedClientItems, selectedClientSetItems} = this.state;
          const clientSet = toClientSet(clientSetConfig);
          const changedIndex = ownershipTypeToSavedClientSets.get("ownedConfigs")
              .findIndex(config => config.get("id") === clientSet.get("id"));
          let changedConfigs = ownershipTypeToSavedClientSets
              .update("ownedConfigs", list => list.set(changedIndex, clientSet));
          const sharedUserIndex = ownershipTypeToSavedClientSets.get("sharedUserConfigs")
              .findIndex(config => config.get("id") === clientSet.get("id"));
          if (sharedUserIndex > -1) {
            changedConfigs = changedConfigs.update("sharedUserConfigs", list => list.set(sharedUserIndex, clientSet));
          }
          const sharedGroupIndex = ownershipTypeToSavedClientSets.get("sharedGroupConfigs")
              .findIndex(config => config.get("id") === clientSet.get("id"));
          if (sharedGroupIndex > -1) {
            changedConfigs = changedConfigs.update("sharedGroupConfigs", list => list.set(sharedGroupIndex, clientSet));
          }
          const selectedClientSetItem = selectedClientSetItems.find(item => item.get("clientSetId") ===
              clientSet.get("id"));
          if (selectedClientSetItem) {
            const updatedClientSetItems = selectedClientSetItems
                .delete(selectedClientSetItem)
                .add(clientSetToItem(clientSet));
            this.setState({
              ownershipTypeToSavedClientSets: changedConfigs,
              selectedClientSetItems: updatedClientSetItems
            });
            this.handleChange(selectedClientItems, updatedClientSetItems, changedConfigs);
          } else {
            this.setState({
              ownershipTypeToSavedClientSets: changedConfigs
            });
          }
          return clientSet;
        });
  },

  handleRemoveClientSet(clientSet, ownershipType) {
    const {selectedClientItems, selectedClientSetItems, ownershipTypeToSavedClientSets} = this.state;

    let removePromise;
    if (ownershipType === "ownedConfigs") {
      removePromise = SavedConfigs.remove(clientSet.get("id"));
    } else if (ownershipType === "sharedUserConfigs") {
      removePromise = SavedConfigs.unshareFromCurrentUser(clientSet.get("id"));
    }

    removePromise.then(() => {
      Popups.success("Removed successfully");

      const index = ownershipTypeToSavedClientSets.get(ownershipType).indexOf(clientSet);
      const updatedOwnershipTypeToSavedClientSets = ownershipTypeToSavedClientSets
          .update(ownershipType, list => list.delete(index));

      const selectedClientSetItem = selectedClientSetItems.find(item => item.get("clientSetId") ===
          clientSet.get("id"));
      if (selectedClientSetItem) {
        const updatedClientSetItems = selectedClientSetItems.delete(selectedClientSetItem);
        this.setState({
          ownershipTypeToSavedClientSets: updatedOwnershipTypeToSavedClientSets,
          selectedClientSetItems: updatedClientSetItems
        });
        this.handleChange(selectedClientItems, updatedClientSetItems, updatedOwnershipTypeToSavedClientSets);
      } else {
        this.setState({
          ownershipTypeToSavedClientSets: updatedOwnershipTypeToSavedClientSets
        });
      }
    }, reason => {
      let errorMsg = `Unable to remove ${clientSet.get("name")}`;
      const responseJSON = reason.responseJSON;
      if (responseJSON && responseJSON.message) {
        errorMsg = responseJSON.message;
      }
      Popups.error(errorMsg);
    });
  },

  handleChange(selectedClientItems, selectedClientSetItems, ownershipTypeToSavedClientSets) {
    const selectedClientIds = selectedClientItems.map(item => item.get("id"));
    const selectedClientSetIds = selectedClientSetItems.map(item => item.get("clientSetId"));
    const allClientIds = getAllClientIds(
        selectedClientIds,
        selectedClientSetIds,
        ownershipTypeToSavedClientSets
    );
    this.props.onChange(
        allClientIds,
        selectedClientIds,
        selectedClientSetIds
    );
  },

  hasChanges() {
    const {selectedClientItems, selectedClientSetItems, ownershipTypeToSavedClientSets} = this.state;
    const stateIds = getAllClientIds(
        selectedClientItems.map(item => item.get("id")),
        selectedClientSetItems.map(item => item.get("clientSetId")),
        ownershipTypeToSavedClientSets
    );
    const {selectedClientIds, selectedClientSetIds} = this.props;
    const propsIds = getAllClientIds(selectedClientIds, selectedClientSetIds, ownershipTypeToSavedClientSets);
    return !Immutable.is(stateIds, propsIds);
  },

  refreshClientSets() {
    this.setState({
      loadingSavedClientSets: true
    });
    loadClientSets()
        .then(ownershipTypeToSavedClientSets => {
          this.setState({
            ownershipTypeToSavedClientSets,
            loadingSavedClientSets: false
          });
        }, () => {
          this.setState({
            loadingSavedClientSets: false
          });
        });
  },

  handleManageClientSetsClick() {
    this.setState({
      manageClientSets: true,
      newClientSet: null
    });
  },

  handleCreateClientSetClick() {
    const {selectedClientItems, selectedClientSetItems, ownershipTypeToSavedClientSets} = this.state;
    const allClientIds = getAllClientIds(
        selectedClientItems.map(item => item.get("id")),
        selectedClientSetItems.map(item => item.get("clientSetId")),
        ownershipTypeToSavedClientSets
    );
    const newClientSet = Immutable.fromJS({
      configType: "CLIENT_SET",
      name: "",
      replaceFilter: true,
      json: {clientIds: allClientIds}
    });
    this.setState({
      manageClientSets: true,
      newClientSet
    });
  },

  closeClientSetsDialog() {
    this.setState({
      manageClientSets: false,
      newClientSet: null
    });
  }
});

const getAllClientIds = memoize((clientIds, clientSetIds, ownershipTypeToSavedClientSets) => {
  clientIds = clientIds.toSet();
  clientSetIds = clientSetIds.toSet();
  return ownershipTypeToSavedClientSets
      .toSet()
      .flatMap(sets => sets)
      .filter(clientSet => clientSetIds.includes(clientSet.get("id")))
      .flatMap(clientSet => clientSet.getIn(["json", "clientIds"]))
      .union(clientIds);
});

const loadInitialItems = (initialIds) => Ajax
    .post({url: "entity-finder", json: {entityType: "CLIENT", ids: initialIds.toJS()}})
    .then(x => Immutable.fromJS(x).map(searchResultToItem));

const suffixWithNameOwnershipTypes = ["sharedUserConfigs", "sharedGroupConfigs"];

const toClientSetItems = ownershipTypeToSavedClientSets =>
    ownershipTypeToSavedClientSets
        .map((clientSets, ownershipType) =>
            clientSets.map(clientSet => clientSetToItem(clientSet, suffixWithNameOwnershipTypes.includes(ownershipType)))
        )
        .toList()
        .flatMap(sets => sets);

const clientSetToItem = (clientSet, suffixWithName) => {
  const owner = Users.getUser(clientSet.get("ownerId"));
  let name;
  if (suffixWithName && owner) {
    name = clientSet.get("name") + " - " + owner.get("firstName");
  } else {
    name = clientSet.get("name");
  }
  return new Immutable.Map({
    id: "CLIENT_SET_" + clientSet.get("id"),
    name,
    type: "CLIENT_SET",
    clientSetId: clientSet.get("id")
  });
};

const searchResultToItem = (result) => new Immutable.Map({
  id: result.get("id"),
  name: result.get("originalCrmId") + " - " + result.get("name")
});

const loadClientSets = () => SavedConfigs
    .getAll("CLIENT_SET")
    .then(ownershipTypeToSavedClientSets => {
      const ownedIds = ownershipTypeToSavedClientSets.get("ownedConfigs").map(config => config.get("id")).toSet();
      return ownershipTypeToSavedClientSets
          .update("ownedConfigs", configs => configs.map(toClientSet))
          .update("sharedUserConfigs", configs => configs
              .filter(config => !ownedIds.has(config.get("id")))
              .map(toClientSet))
          .update("sharedGroupConfigs", configs => configs
              .filter(config => !ownedIds.has(config.get("id")))
              .map(toClientSet));
    });

const toClientSet = config => config.updateIn(["json", "clientIds"], clientIds => clientIds.toSet());
