/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {css} from "@emotion/react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Select from "js/common/views/inputs/immutable-react-select";

import Overlay from "js/common/views/overlay";
import PlaceholderTextField from "js/common/views/inputs/placeholder-text-field";
import PhotoUploadPanel from "js/admin/users/photo-upload-panel";
import EditStandardAppAssignments from "js/admin/users/edit-standard-app-assignments";
import EditAdminAppAssignments from "js/admin/users/edit-admin-app-assignments";
import Link from "js/admin/link";
import InheritedFromGroupInfo from "js/admin/inherited-from-group-info";
import AudioPlayer from "js/admin/simple-audio-player";
import DealMusicPicker from "js/admin/deal-music-picker";
import PagePicker from "js/common/views/inputs/page-picker";
import InheritedLocalePicker from "js/common/views/inputs/inherited-locale-picker";
import Layout from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import {getLabelForApp} from "js/admin/users/app-assignment-labels";
import currentClient from "js/common/repo/backbone/current-client";
import * as dealMusicRepo from "js/common/repo/deal-music-repo";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import {Radio as RadioButton, RadioGroup as RadioButtonGroup, FormControl, FormControlLabel} from "@mui/material";
import Checkbox from "js/common/views/inputs/checkbox";

import UserAvatar from "js/common/views/user-avatar";
import AdminSwitchingUsersPicker from "js/common/views/switching-user/admin-switching-user-picker";
import * as Permissions from "js/common/permissions";

const silentMp3Id = dealMusicRepo.getSilentMp3Id();

const selectLabel = css`
  font-size: 11px;
  color: #757575;
  margin: 1rem 0 -0.7rem 0;
  padding: 0;
`;

const ThemeReceivingEditUserPanel = createReactClass({
  mixins: [PureRenderMixin],
  displayName: "EditUserPanel",

  getInitialState() {
    return {
      isDealMusicPickerOpen: false,
      inheritsPageFromGroup: this.props.user.get("pageId") === null
    };
  },

  render() {
    const client = currentClient;
    const {
      user,
      permissions,
      appAssignments,
      isLoading,
      onUploadClick,
      onChange,
      onPermissionChange,
      onAppPermissionChange,
      theme
    } = this.props;

    const showLoginFormComponents = userCanUseLoginForm(user);
    return (
        <div>
          {renderBreadcrumbSection(user, theme)}

          {currentClient.hasPermission("EDIT_PHOTO_URL") &&
              renderPhotoSection(user, onUploadClick)}

          {showLoginFormComponents
              && renderUsernameSection(user, onChange)}

          {showLoginFormComponents
              && renderEmailSection(user, onChange)}

          {!user.get("crmUser")
              && renderNameSection(user, onChange)}

          {client.hasPermission("EDIT_PHOTO_URL") &&
              renderPhotoUrlSection(user, onChange)}

          {this.renderAbilitiesSection(user, permissions, onPermissionChange, client, showLoginFormComponents)}

          {this.renderUserSwitchingSection()}

          {this.renderDashboardEditingSection(user, permissions, appAssignments, onPermissionChange, client)}

          {this.renderUserSeesSection(user)}

          {this.renderLandingPageSection(user, onChange)}

          {this.renderOneViewPageSection()}

          {this.renderLocaleSection()}

          {client.canAccessApp("CAROUSEL_ADMIN")
              && this.renderDealMusicSection()}

          {this.renderLoginControlSection(user, showLoginFormComponents)}

          {renderPermissionsSections(user, appAssignments, onAppPermissionChange, theme)}

          {isLoading && <Overlay />}
        </div>
    );
  },

  getLandingPageItems() {
    const {appAssignments} = this.props;
    const landingPageOptions = appAssignments
        .filter(appAssignment => !appAssignment.includes("_ADMIN"))
        .sortBy(appAssignment => appAssignment.toLowerCase())
        .toList();
    return landingPageOptions.map(appAssignment => {
      const label = getLabelForApp(appAssignment);
      return (Immutable.Map({
        label,
        value: appAssignment
      }));
    });
  },

  renderUserSeesSection(user) {
    let userSeesOptions = Immutable.fromJS([
      {value: "ALL", label: "Everyone (default)"},
      {value: "GROUP_AND_BELOW", label: "Their Group"}]);
    if (!Users.isObserver(user) || user.get("dataVisibility") === "SELF") {
      userSeesOptions = userSeesOptions.push(Immutable.fromJS({value: "SELF", label: "Themselves Only"}));
    }

    return <Layout
        allSmall={12}
        style={{marginTop: "-14px"}}
        columnStyle={{
          marginTop: "0.5rem",
          marginBottom: "0.5rem"
        }}>
      <p css={selectLabel}>User Sees</p>
      <Select
          placeholder="User Sees"
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          selectedValue={user.get("dataVisibility")}
          options={userSeesOptions}
          onChange={(dataVisibility) => this.handleUserDataVisibilityChange(dataVisibility)} />
    </Layout>;
  },

  renderLandingPageSection(user, onChange) {

    const landingPageItems = this.getLandingPageItems();
    const selectedLandingPage = user.get("landingPage");

    return (
        <Layout
            allSmall={12}
            columnStyle={{
              marginTop: "0.5rem",
              marginBottom: "0.5rem"
            }}>
          <p css={selectLabel}>Landing Page</p>
          <Select
              placeholder="Landing page"
              isClearable={false}
              isMulti={false}
              isSearchable={false}
              selectedValue={selectedLandingPage}
              options={landingPageItems}
              onChange={(landingPage) => onChange({landingPage})} />
        </Layout>
    );
  },

  renderOneViewPageSection() {
    const {user, oneViewPages, onChange} = this.props;
    return (
        <div>
          <Layout allSmall={12} rowStyle={{paddingTop: "1rem"}}>
            <label style={labelStyle}>OneView Page</label>
          </Layout>
          <PagePicker
              pages={oneViewPages}
              isUserContext={true}
              userOrGroup={user}
              onChange={onChange} />
        </div>
    );
  },

  renderUserSwitchingSection() {
    const {user, permissions, userById, userIdsToLogInAs, onUsersToLoginAsChange, idToNameOnlyUser} = this.props;

    return <div>
      <Layout allSmall={12} rowStyle={{paddingTop: "1rem"}}>
        <label style={labelStyle}>Allowed Users to Log In As</label>
      </Layout>
      <AdminSwitchingUsersPicker
          user={user}
          userPermissions={permissions}
          userById={userById.filterNot(u => Users.isNew(u))}
          idToNameOnlyUser={idToNameOnlyUser}
          userIdsToLogInAs={userIdsToLogInAs}
          getGroupBreadcrumbsLabel={getGroupBreadcrumbsLabel}
          onChange={onUsersToLoginAsChange} />
    </div>;
  },

  renderLocaleSection() {
    const {user, idToLocale, onChange} = this.props;
    return (
        <div>
          <Layout allSmall={12} rowStyle={{paddingTop: "1rem"}}>
            <label style={labelStyle}>Date Format</label>
          </Layout>
          <InheritedLocalePicker
              idToLocale={idToLocale}
              user={user}
              onChange={onChange} />
        </div>
    );
  },

  renderAbilitiesSection(user, permissions, onPermissionChange, client, showLoginFormComponents) {

    const shareConfigsData = Immutable.fromJS([
      {
        accessName: "REPORTING",
        permissionName: "SHARE_SND_REPORT",
        displayName: "Slice & Dice"
      },
      {
        accessName: "CHARTING",
        permissionName: "SHARE_CHART_PAGE",
        displayName: "Trends"
      },
      {
        accessName: "ONPOINT",
        permissionName: "SHARE_ONPOINT_REPORT",
        displayName: "OnPoint"
      },
      {
        accessName: "DASHBOARDS",
        permissionName: "SHARE_DASHBOARD",
        displayName: "Dashboards"
      }
    ]);

    const accessibleApps = shareConfigsData.filter(config => client.canAccessApp(config.get("accessName")));
    const shouldShowShareConfig = !accessibleApps.isEmpty();
    const shareConfigsPermissions = accessibleApps.map(config => config.get("permissionName"));
    const shareConfigsDisplayNames = accessibleApps.map(config => config.get("displayName"));

    return <div>
      <Layout allSmall={12} allMedium={7}>
        <Checkbox
            label="Visible"
            style={{marginTop: "0", width: 250}}
            checked={user.get("state") === "VISIBLE"}
            disabled={user.get("dataVisibility") === "SELF"}
            onCheck={(e, isVisible) =>
                this.handleUserVisibilityChange(isVisible)
            } />
      </Layout>
      {showLoginFormComponents && <Layout allSmall={12} allMedium={7}>
        <Checkbox
            label="Can Login"
            checked={user.get("hasLogin")}
            onCheck={(e, hasLogin) =>
                this.handleUserHasLoginChange(hasLogin)
            }
            style={{marginTop: "-12px", width: 250}} />
      </Layout>}
      <Layout allSmall={12} allMedium={7}>
        <Checkbox
            label="Data Details Clickthrough"
            checked={permissions.some(p => p === "CLICK_THROUGH")}
            onCheck={(e, isChecked) =>
                onPermissionChange(
                    isChecked
                        ? permissions.add("CLICK_THROUGH")
                        : permissions.remove("CLICK_THROUGH"))}
            style={{marginTop: "-12px", width: 250}} />
      </Layout>
      {client.hasPermission("QUICKVIEW_CT") &&
          (<Layout allSmall={12} allMedium={7}>
            <Checkbox
                label="Quickview Clickthrough"
                checked={permissions.some(p => p === "QUICKVIEW_CT")}
                onCheck={(e, isChecked) =>
                    onPermissionChange(
                        isChecked
                            ? permissions.add("QUICKVIEW_CT")
                            : permissions.remove("QUICKVIEW_CT"))}
                style={{marginTop: "-12px", width: 250}} />
          </Layout>)}
      {client.hasPermission("LEADERBOARD_CT") &&
          (<Layout allSmall={12} allMedium={7}>
            <Checkbox
                label="Leaderboard Clickthrough"
                checked={permissions.some(p => p === "LEADERBOARD_CT")}
                onCheck={(e, isChecked) =>
                    onPermissionChange(
                        isChecked
                            ? permissions.add("LEADERBOARD_CT")
                            : permissions.remove("LEADERBOARD_CT"))}
                style={{marginTop: "-12px", width: 250}} />
          </Layout>)}
      {client.hasPermission("TARGET_CT") &&
          (<Layout allSmall={12} allMedium={7}>
            <Checkbox
                label="Target Clickthrough"
                checked={permissions.some(p => p === "TARGET_CT")}
                onCheck={(e, isChecked) =>
                    onPermissionChange(
                        isChecked
                            ? permissions.add("TARGET_CT")
                            : permissions.remove("TARGET_CT"))}
                style={{marginTop: "-12px", width: 250}} />
          </Layout>)}
      <Layout allSmall={12} allMedium={7}>
        <Checkbox
            label="IP Restricted Access"
            checked={user.get("ipRestricted")}
            onCheck={(e, ipRestricted) =>
                this.handleUserIpRestrictedAccessChange(ipRestricted)}
            style={{marginTop: "-12px", width: 250}} />
      </Layout>
      <Layout allSmall={12} allMedium={7}>
        <Checkbox
            label="Export to File"
            checked={permissions.some(p => p === "EXPORT_FILE")}
            onCheck={(e, isChecked) =>
                onPermissionChange(
                    isChecked
                        ? permissions.add("EXPORT_FILE")
                        : permissions.remove("EXPORT_FILE"))}
            style={{marginTop: "-12px", width: 250}} />
      </Layout>
      {shouldShowShareConfig && (
          <Layout allSmall={12} allMedium={7}>
            {shareConfigsPermissions.map(permission => {
              return (
                  <Checkbox
                      label={`Share ${accessibleApps.filter(p => p.get("permissionName") === permission).first()
                          .get("displayName")}`}
                      checked={permissions.some(p => p === permission)}
                      onCheck={(e, isChecked) =>
                          onPermissionChange(
                              isChecked
                                  ? permissions.add(permission)
                                  : permissions.remove(permission))}
                      style={{marginTop: "-12px", width: 250}}
                  />
              );
            })}
          </Layout>
      )}

      <Layout allSmall={12} allMedium={7}>
        <Checkbox
            label="OneView Super User"
            checked={permissions.some(p => p === "ONEVIEW_PAGE_SUPER_USER")}
            onCheck={(e, isChecked) =>
                onPermissionChange(
                    isChecked
                        ? permissions.add("ONEVIEW_PAGE_SUPER_USER")
                        : permissions.remove("ONEVIEW_PAGE_SUPER_USER"))}
            style={{marginTop: "-12px", width: 250}} />
      </Layout>
      {client.hasPermission("ORGANISATION_FILTER") &&
          (<span>
        <Layout allSmall={12} allMedium={7}>
          <Checkbox
              label="Share Client Sets"
              checked={permissions.some(p => p === "SHARE_CLIENT_SET")}
              onCheck={(e, isChecked) =>
                  onPermissionChange(
                      isChecked
                          ? permissions.add("SHARE_CLIENT_SET")
                          : permissions.remove("SHARE_CLIENT_SET"))}
              style={{marginTop: "-12px", width: 250}} />
        </Layout>
      </span>)}
    </div>;
  },

  renderDashboardEditingSection(user, permissions, appAssignments, onPermissionChange, client) {
    const canAccessDashboards = client.canAccessApp("DASHBOARDS") && appAssignments.has("DASHBOARDS");
    const canEditDashboards = client.hasPermission(Permissions.clientPermissions.canEditDashboards);

    let value;
    if (permissions.has("DASHBOARD_FULL_EDITOR")) {
      value = "FULL";
    } else if (permissions.has("DASHBOARD_BASIC_EDITOR")) {
      value = "BASIC";
    } else {
      value = "NONE";
    }

    let editorLevelOptions = Immutable.fromJS([{
      label: "Full - Create Dashboards from Scratch",
      value: "FULL"
    }, {
      label: "Basic - Make Layout Changes to Existing Dashboards",
      value: "BASIC"
    }, {
      label: "None",
      value: "NONE"
    }
  ]);

    return canAccessDashboards && <Layout
        allSmall={12}
        style={{marginTop: "-14px"}}
        columnStyle={{
          marginTop: "0.5rem",
          marginBottom: "0.5rem"
        }}>
      <p css={selectLabel}>Dashboard Editing Abilities</p>
      <Select
          isDisabled={!canEditDashboards}
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          selectedValue={value}
          options={editorLevelOptions}
          onChange={editorLevel => {
            switch (editorLevel) {
              case "FULL":
                onPermissionChange(permissions.add("DASHBOARD_FULL_EDITOR").remove("DASHBOARD_BASIC_EDITOR"));
                break;
              case "BASIC":
                onPermissionChange(permissions.remove("DASHBOARD_FULL_EDITOR").add("DASHBOARD_BASIC_EDITOR"));
                break;
              case "NONE":
                onPermissionChange(permissions.remove("DASHBOARD_FULL_EDITOR").remove("DASHBOARD_BASIC_EDITOR"));
                break;
              default:
                throw new Error("unknown dashboard editor level: " + editorLevel);
            }
          }} />
    </Layout>;
  },

  renderLoginControlSection(user, showLoginFormComponents) {
    return <Layout
        allSmall={12}
        allMedium={6}
        columnStyle={{marginTop: "1rem"}}
        floatLastColumnLeft={true}>
      {showLoginFormComponents && <TextButton
          label="Reset Password"
          onClick={() => this.props.onResetUserPassword(user)} />}
      {showLoginFormComponents && <TextButton
          label="Set Password"
          onClick={() => this.props.onSetUserPassword()} />}
      <TextButton
          label="Deactivate User"
          onClick={() => this.props.onDeactivateClick()} />
    </Layout>;
  },

  renderDealMusicSection() {
    const {user} = this.props;
    const {isDealMusicPickerOpen} = this.state;
    const dealMusicId =
        user.get("dealMusicId") || getGroupDealMusicId(user.get("groupId"));
    return (
        <div style={{marginBottom: "0.5rem"}}>
          <Layout allSmall={12} rowStyle={{paddingTop: "1rem"}}>
            <label style={labelStyle}>Deal Music</label>
            {this.getUserDealMusicInfo()}
          </Layout>
          {isDealMusicPickerOpen && (
              <DealMusicPicker
                  selectedDealMusicId={dealMusicId}
                  onChange={this.handleDealMusicChange}
                  onCloseRequest={this.closeDealMusicPicker} />)}
        </div>
    );
  },

  getUserDealMusicInfo() {
    const {user, theme} = this.props;
    const isInheritedFromGroup = !user.get("dealMusicId");
    const groupId = user.get("groupId");
    const group = Groups.getGroup(groupId);
    const dealMusicId = isInheritedFromGroup
        ? group.get("dealMusicId")
        : user.get("dealMusicId");
    const hasNoDealMusic =
        dealMusicId === silentMp3Id || !dealMusicRepo.get(dealMusicId);
    const dealMusic = !hasNoDealMusic
        ? dealMusicRepo.get(dealMusicId)
        : null;
    const dealMusicTextStyle = {
      fontSize: "0.9rem",
      lineHeight: "34px",
      verticalAlign: "middle"
    };

    return (
        <div>
          <div>
            {hasNoDealMusic && (
                <span style={dealMusicTextStyle}>No deal music</span>)}
            {!hasNoDealMusic && (
                <span>
                  {!dealMusic && (
                      <span style={dealMusicTextStyle}>
                          <i
                              className="fa fa-exclamation-triangle"
                              style={{
                                color: "#f00",
                                paddingRight: 8
                              }} />
                          Deal music not found
                      </span>)}
                  {dealMusic && (
                      <span>
                        <span style={dealMusicTextStyle}>
                            {dealMusic.get("description")}
                        </span>
                        <span
                            style={{
                              paddingLeft: "0.5rem",
                              paddingRight: "1rem"
                            }}>
                            <AudioPlayer
                                source={dealMusic.get("url")} />
                        </span>
                      </span>)}
                </span>
            )}
            {!this.state.isDealMusicPickerOpen &&
                !isInheritedFromGroup &&
                !hasNoDealMusic && (
                    <Link
                        theme={theme}
                        label="Change"
                        onClick={this.openDealMusicPicker} />
                )}
          </div>
          {isInheritedFromGroup && (
              <InheritedFromGroupInfo originalGroupId={groupId} theme={theme} />)}
          <Layout allSmall={12} collapseRow={true}>
            <FormControl component="fieldset">
              <RadioButtonGroup
                  name="deal-music-setting"
                  onChange={this.onDealMusicSettingChange}
                  value={this.getUserDealMusicSetting()}>
                <FormControlLabel value="silent" control={<RadioButton color="primary" />} label="No deal music" />
                <FormControlLabel
                    value="inherit"
                    control={<RadioButton color="primary" />}
                    label="Inherit from group" />
                <FormControlLabel value="custom" control={<RadioButton color="primary" />} label="Custom deal music" />
              </RadioButtonGroup>
            </FormControl>
          </Layout>
        </div>
    );
  },

  getUserDealMusicSetting() {
    const {user} = this.props;
    if (user.get("dealMusicId") === silentMp3Id) {
      return "silent";
    }
    if (!user.get("dealMusicId")) {
      return "inherit";
    }
    return "custom";
  },

  onDealMusicSettingChange(e, setting) {
    switch (setting) {
      case "silent":
        this.handleDealMusicChange(silentMp3Id);
        this.closeDealMusicPicker();
        break;
      case "inherit":
        this.handleDealMusicChange(null);
        this.closeDealMusicPicker();
        break;
      case "custom":
        this.handleDealMusicChange(getDefaultDealMusicId());
        this.openDealMusicPicker();
        break;
      default:
        throw new Error("Unsupported deal music setting:", setting);
    }
  },

  openDealMusicPicker() {
    this.setState({
      isDealMusicPickerOpen: true
    });
  },

  closeDealMusicPicker() {
    this.setState({
      isDealMusicPickerOpen: false
    });
  },

  handleUserHasLoginChange(hasLogin) {
    this.props.onChange({hasLogin});
  },

  handleUserVisibilityChange(isVisible) {
    const newState = isVisible ? "VISIBLE" : "INVISIBLE";
    this.props.onChange({state: newState});
  },

  handleUserDataVisibilityChange(dataVisibility) {
    this.props.onChange({dataVisibility});
  },

  handleUserIpRestrictedAccessChange(ipRestricted) {
    this.props.onChange({ipRestricted});
  },

  handleDealMusicChange(dealMusicId) {
    this.props.onChange({dealMusicId});
  }
});

const renderPermissionsSections = (user, appAssignments, onAppPermissionChange, theme) => {
  return <Layout
      allSmall={12}
      allLarge={6}
      columnStyle={{marginTop: "1rem"}}>
    <EditStandardAppAssignments
        theme={theme}
        dataVisibility={user.get("dataVisibility")}
        landingPage={user.get("landingPage")}
        appAssignments={appAssignments}
        onChange={onAppPermissionChange}
        allowedPerms={getStandardAppsForCurrentClient()} />
    <EditAdminAppAssignments
        theme={theme}
        landingPage={user.get("landingPage")}
        appAssignments={appAssignments}
        onChange={onAppPermissionChange}
        allowedPerms={getAdminAppsForCurrentClient()} />
  </Layout>;
};

const renderUsernameSection = (user, onChange) => {
  return <Layout allSmall={12}>
    <PlaceholderTextField
        label="Username"
        value={user.get("username") || ""}
        onChange={username => onChange({username})} />
  </Layout>;
};

const renderEmailSection = (user, onChange) => {
  return <Layout allSmall={12}>
    <PlaceholderTextField
        label="Email"
        value={user.get("email")}
        onChange={email => onChange({email})} />
  </Layout>;
};

const renderNameSection = (user, onChange) => {
  return <Layout allSmall={12}>
    <PlaceholderTextField
        inputStyle={{fontSize: "16px"}}
        label="First Name"
        value={user.get("firstName")}
        onChange={firstName => onChange({firstName})} />
    <PlaceholderTextField
        inputStyle={{fontSize: "16px"}}
        label="Last Name"
        value={user.get("lastName")}
        onChange={lastName => onChange({lastName})} />
  </Layout>;
};

const renderPhotoUrlSection = (user, onChange) => {
  return <Layout allSmall={12}>
    <PlaceholderTextField
        inputStyle={{fontSize: "16px !important"}}
        label="Photo URL"
        value={user.get("photoURL")}
        onChange={photoURL => onChange({photoURL})} />
  </Layout>;
};

const renderBreadcrumbSection = (user, theme) => {
  return <Layout allSmall={12}>
    <h3 style={{color: theme.palette.primary.main, textTransform: "uppercase"}}>
      <i
          className="fa fa-user"
          style={{paddingRight: 12}} />
      {user.get("fullName")}
    </h3>
    <h4
        style={{
          display: "table",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem"
        }}>
      <i
          className="fa fa-group"
          style={{display: "table-cell", paddingRight: 8}} />
      {getGroupBreadcrumbsLabel(user.get("groupId"))}
    </h4>
    <UserAvatar user={user} style={{height: 100}} />
  </Layout>;
};

const renderPhotoSection = (user, onUploadClick) => {
  return <Layout
      allSmall={12}
      collapse={true}
      columnStyle={{marginTop: "1rem"}}>
    <PhotoUploadPanel
        user={user}
        onUploadClick={onUploadClick} />
  </Layout>;
};

const getGroupBreadcrumbsLabel = groupId => {
  const group = Groups.getGroup(groupId);
  if (!group) {
    return (
        <span style={{display: "table-cell"}}>
                <i
                    className="fa fa-exclamation-triangle"
                    style={{color: "#f00", paddingRight: 8}} />
                No Group assigned
            </span>
    );
  } else {
    const breadcrumbs = group.get("breadcrumbs");
    return (
        <span style={{display: "table-cell"}}>
                {breadcrumbs.map((crumb, index) => {
                  const isLastItem = index === breadcrumbs.length - 1;
                  if (isLastItem) {
                    return <span key={crumb}>{crumb}</span>;
                  } else {
                    return (
                        <span key={crumb}>
                                {crumb}
                          <i
                              className="fa fa-chevron-right"
                              style={{paddingLeft: 8, paddingRight: 8}} />
                            </span>
                    );
                  }
                })}
            </span>
    );
  }
};

const getGroupDealMusicId = groupId => {
  const dealMusic = getGroupDealMusic(groupId);
  return dealMusic ? dealMusic.get("dealMusicId") : null;
};
const getGroupDealMusic = groupId => {
  const group = Groups.getGroup(groupId);
  const isRootGroup = !group.get("parentId");
  if (isRootGroup) {
    return group.get("dealMusicId")
        ? dealMusicRepo.get(group.get("dealMusicId"))
        : getDefaultDealMusic();
  }
  const dealMusicId = group.get("dealMusicId")
      ? group.get("dealMusicId")
      : getDefaultDealMusic();
  return group.get("dealMusicId")
      ? dealMusicRepo.get(dealMusicId)
      : getInheritedDealMusic(group.get("inheritedMusicGroupId"));
};
const getInheritedDealMusic = inheritedMusicGroupId => {
  const group = Groups.getGroup(inheritedMusicGroupId);
  return dealMusicRepo.get(group.get("dealMusicId")) || getDefaultDealMusic();
};
const getDefaultDealMusic = () => dealMusicRepo.getAll().first();
const getDefaultDealMusicId = () => {
  const dealMusic = getDefaultDealMusic();
  return dealMusic ? dealMusic.get("dealMusicId") : null;
};

const getStandardAppsForCurrentClient = () =>
    currentClient
        .get("appAssignments")
        .filter(app => app.get("app").indexOf("ADMIN") === -1)
        .map(app => app.get("app"))
        .sort();

// NOTE keeping data explorer permission hidden from user admins
const getAdminAppsForCurrentClient = () =>
    currentClient
        .get("appAssignments")
        .filter(app => app.get("app").indexOf("_ADMIN") > -1)
        .map(app => app.get("app"))
        .filter(app => app !== "DATA_EXPLORER_ADMIN")
        .sort();

const userCanUseLoginForm = user => Users
        .getUser(user.get("id"))
        .get("ignoreRequireSsoLogin")
    || !currentClient.get("requireSsoLogin");

const labelStyle = {
  cursor: "default",
  fontSize: "12px",
  color: "#999"
};

const EditUserPanel = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ThemeReceivingEditUserPanel theme={theme} {...props} />;
};

export default EditUserPanel;