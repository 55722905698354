/** @jsxImportSource @emotion/react */

import React, {useState} from "react";
import Drawer from "js/common/views/drawer";
import ClientFilter from "js/common/views/client-filter/client-filter";
import {TextButton} from "js/common/views/inputs/buttons";
import Immutable from "immutable";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import TagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";

import useMountEffect from "js/common/utils/use-mount-effect";
import pure from "js/common/views/pure.js";

import Tooltip from "js/common/views/tooltips";
import currentClient from "js/common/repo/backbone/current-client";

import * as Styles from "js/common/views/filter-drawer/filters-drawer.styles";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const FiltersDrawer = props => {
  const [allClientIds, setAllClientIds] = useState(Immutable.Set());
  const [filteredClientIds, setFilteredClientIds] = useState(Immutable.OrderedSet());
  const [filteredClientSetIds, setFilteredClientSetIds] = useState(Immutable.OrderedSet());
  const [matchAnyTagIds, setMatchAnyTagIds] = useState(Immutable.List());
  const [matchAllTagIds, setMatchAllTagIds] = useState(Immutable.List());
  const [excludedTagIds, setExcludedTagIds] = useState(Immutable.List());
  const [latestAppliedFilters, setLatestAppliedFilters] = useState(Immutable.Map());
  const {theme} = React.useContext(CustomThemeContext);

  const {
    open,
    onRequestOpen,
    onRequestClose,
    config,
    clientFilterConfig,
    onFilterChanges
  } = props;

  useMountEffect(() => {
    const savedFiltersFromProps = Immutable.fromJS({
      allClientIds: config.get("clientIds", Immutable.Set()),
      filteredClientIds: clientFilterConfig
          ? clientFilterConfig.get("clientIds", Immutable.OrderedSet())
          : Immutable.OrderedSet(),
      filteredClientSetIds: clientFilterConfig
          ? clientFilterConfig.get("clientSetIds", Immutable.OrderedSet())
          : Immutable.OrderedSet(),
      matchAnyTagIds: config.get("matchAnyTagIds", Immutable.List()),
      matchAllTagIds: config.get("matchAllTagIds", Immutable.List()),
      excludedTagIds: config.get("excludedTagIds", Immutable.List())
    });
    handleClientSelectionChange(
        savedFiltersFromProps.get("allClientIds"),
        savedFiltersFromProps.get("filteredClientIds"),
        savedFiltersFromProps.get("filteredClientSetIds"));
    handleTagSelectionChange(
        savedFiltersFromProps.get("matchAnyTagIds"),
        savedFiltersFromProps.get("matchAllTagIds"),
        savedFiltersFromProps.get("excludedTagIds"));
    setLatestAppliedFilters(savedFiltersFromProps);
  }, []);

  const handleApplyFilters = (allClientIds, selectedClientIds, selectedClientSetIds) => {
    onFilterChanges(
        allClientIds,
        selectedClientIds,
        selectedClientSetIds,
        matchAnyTagIds,
        matchAllTagIds,
        excludedTagIds);
    setLatestAppliedFilters(Immutable.fromJS({
      allClientIds,
      filteredClientIds,
      filteredClientSetIds,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds
    }));
    onRequestClose();
  };

  const handleUndoFilters = () => {
    handleClientSelectionChange(
        latestAppliedFilters.get("allClientIds"),
        latestAppliedFilters.get("filteredClientIds"),
        latestAppliedFilters.get("filteredClientSetIds"));
    handleTagSelectionChange(
        latestAppliedFilters.get("matchAnyTagIds"),
        latestAppliedFilters.get("matchAllTagIds"),
        latestAppliedFilters.get("excludedTagIds"));
  };

  const allowUndo = () => {
    const currentFilters = Immutable.fromJS({
      allClientIds,
      filteredClientIds,
      filteredClientSetIds,
      matchAllTagIds,
      matchAnyTagIds,
      excludedTagIds
    });
    return !currentFilters.equals(latestAppliedFilters);
  };

  const handleClearFilters = () => {
    handleClientSelectionChange(Immutable.Set(), Immutable.OrderedSet(), Immutable.OrderedSet());
    handleTagSelectionChange(Immutable.List(), Immutable.List(), Immutable.List());
  };

  const handleClientSelectionChange = (allClientIds, selectedClientIds, selectedClientSetIds) => {
    allClientIds && setAllClientIds(allClientIds);
    selectedClientIds && setFilteredClientIds(selectedClientIds);
    selectedClientSetIds && setFilteredClientSetIds(selectedClientSetIds);
  };

  const handleTagSelectionChange = (matchAnyTagIds, matchAllTagIds, excludedTagIds) => {
    matchAnyTagIds && setMatchAnyTagIds(matchAnyTagIds);
    matchAllTagIds && setMatchAllTagIds(matchAllTagIds);
    excludedTagIds && setExcludedTagIds(excludedTagIds);
  };

  const DrawerTitle = pure(({style, title, icon}) => {
    return <>
      {icon && <span><i className={icon} /></span>}
      <h3 css={style}>{title}</h3>
    </>;
  }, "DrawerTitle");

  return (
      <Drawer
          open={open}
          onRequestOpen={onRequestOpen}
          onRequestClose={() => {
            //revert unsaved changes on close
            allowUndo && handleUndoFilters();
            onRequestClose();
          }}
          showCloseButton
          title="Filters"
          openRight={true}
          width={598}>
        <>
          {currentClient.hasPermission("ORGANISATION_FILTER") &&
              <div css={Styles.filterSectionStyles}>
                <DrawerTitle style={Styles.filterHeadingStyle(theme)} title="Filter by Client" icon="bhi-company" />
                <div style={{marginTop: 10}}>
                  <ClientFilter
                      flushChangesImmediately
                      bgColor="card"
                      selectedClientIds={filteredClientIds}
                      selectedClientSetIds={filteredClientSetIds}
                      onChange={handleClientSelectionChange} />
                </div>
              </div>}

          {currentClient.canAccessApp("TAGS_ADMIN") &&
              <div className="tags-filters" css={Styles.tagFilterStyles(theme)}>
                <DrawerTitle style={Styles.filterHeadingStyle} title="Filter by Tag" icon="bhi-category-tags" />
                <TagFilter
                    label="Match Any Tags"
                    theme="oneview-tags"
                    labelStyle={Styles.labelStyle(theme.palette.app.matchAnyColor)}
                    tagIds={matchAnyTagIds}
                    showIcon={false}
                    onChange={tagIds => setMatchAnyTagIds(tagIds)} />
                <TagFilter
                    label="Match All Tags"
                    theme="oneview-tags"
                    labelStyle={Styles.labelStyle(theme.palette.app.matchAllColor)}
                    tagIds={matchAllTagIds}
                    showIcon={false}
                    onChange={tagIds => setMatchAllTagIds(tagIds)} />
                <TagFilter
                    label="Exclude Tags"
                    theme="oneview-tags"
                    labelStyle={Styles.labelStyle(theme.palette.app.excludeColor)}
                    tagIds={excludedTagIds}
                    showIcon={false}
                    onChange={tagIds => setExcludedTagIds(tagIds)} />
              </div>}
          <DrawerControls
              handleApply={() => {handleApplyFilters(allClientIds, filteredClientIds, filteredClientSetIds);}}
              handleUndo={handleUndoFilters}
              handleClear={handleClearFilters}
              allowUndo={allowUndo()}
              theme={theme} />
        </>
      </Drawer>
  );
};

FiltersDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestOpen: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  clientFilterConfig: ImmutablePropTypes.map,
  onFilterChanges: PropTypes.func.isRequired
};

const DrawerControls = pure(({handleApply, handleUndo, handleClear, allowUndo, theme}) => {
  return <div css={Styles.drawerControlsStyle(theme)}>
    <TextButton
        type="primary"
        label="Apply"
        disabled={false}
        onClick={handleApply}
        css={Styles.applyButtonStyle}
    />
    <div style={{display: "inline-block"}}
         css={Styles.applyButtonStyle}>
      <Tooltip text={allowUndo ? "Revert to last set of applied filters" : "No changes made"} position="top">
        <TextButton
            label="Undo changes"
            disabled={!allowUndo}
            onClick={handleUndo}
            css={Styles.resetButtonStyle} />
      </Tooltip>
    </div>
    <TextButton
        label="Clear"
        disabled={false}
        onClick={handleClear}
        css={Styles.resetButtonStyle} />
  </div>;
}, "DrawerControls");

export default FiltersDrawer;
